import { useAppState } from "@/state/app/useAppState";
import { useState, ChangeEvent, useEffect, useMemo, useCallback } from "react";
import { RecoilState, atom, atomFamily, selectorFamily, useRecoilState, useResetRecoilState, useSetRecoilState } from "recoil";
import { useForm } from "./useForm";

export type FormFieldType<T=any> = {
    value: T;
    error: string;
    test: RegExp;
    valid: boolean;
}

export function NewFormField<T=any>(value:T, test:RegExp, error:string){
    return {
        value,
        error,
        test,
        valid: false,
    } 
}

export type FormItems = {
    [key:string]:FormFieldType<any>
}

type FormsStateType = {
    [key:string]: {
        initialState: FormItems;
        currentState: FormItems;
    }
}

export const FormsState = atom<FormsStateType>({
    key: 'FormState',
    default: {},
})

export type useFormStateResponse<T extends FormItems> = {
    state: T;
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

export function useCreateForm<T extends FormItems>(id:string, initialState:T): useFormStateResponse<T> {

    const setFormsState = useSetRecoilState(FormsState);
    const Form = useForm<T>(id)

    useEffect(() => {
        setFormsState(currentFormsState => ({
            ...currentFormsState,
            [id]: {
                initialState: { ...initialState },
                currentState: { ...initialState },
            }
        }))
    }, [])

    return {
        ...Form
    }

}