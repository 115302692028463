import { Button } from "@gamesheet/ui";
import { useEffect } from "react";
import IframeWithOtp from "@/components/utils/IframeWithOtp";
import { useAppState } from "@/state/app/useAppState";
import { Iframe, useIframeLoadingState } from "@/state/app/useIframeLoading";
import { useSeasonsData } from "@/state/data/useSeasonTeams";

export function ImportFromCsv(){
    
    const app = useAppState()
    const data = useSeasonsData()
    const season = data.seasons[0]

    useEffect(() => {
        app.ui('leftTray').set(<Button onClick={app.navigateOnClick('/roster/team')}>Back</Button>)
        return () => app.ui('leftTray').clear()
    }, [])

    return <IframeWithOtp id="importRosterFromCsv" src={`${app.config.apps.leagueDashboard}/seasons/${season.id}/teams/${season.team.id}/roster/import-from-csv`} />
}