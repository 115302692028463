import { useEffect, useState } from "react";
import styled from "styled-components";
import { Modal, Card, Alert } from "@gamesheet/ui";
import { useIpadApiKeys } from "./useIpadApiKeys"
import { useAppState } from "@/state/app/useAppState";

export const ModalContainer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgb(0,0,0,0.5);
    z-index: 5200;
`

export const IpadKeyValue = styled.div`
    padding-right: 10px;

    .bold {
        color: #404247;
        font-family: Rubik;
        font-size: 1rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .code {
        font-family: Courier, monospace;
        font-style: normal;
    }
`

const EmptyNotice = styled.div`
    padding-bottom: 1.1rem;
    font-size: 1.2rem;
    font-family: Rubik;
    font-style: italic;
    line-height: normal;
    text-align: center;
`

export function IpadApiKeys() {
    const app = useAppState()
    const { isOpen, close, loading, apiKeys } = useIpadApiKeys();
    const [showCard, setShowCard] = useState<{ [id: number]: boolean }>({});

    useEffect(() => {
        if (app.layout.showDesktop) {
            setShowCard(apiKeys.reduce((acc, k) => ({ ...acc, [k.id]: true }), {}));
        } else {
            setShowCard({});
        }
    }, [app.layout.showDesktop, JSON.stringify(apiKeys)])

    if (!isOpen) {
        return <></>;
    }

    return (
        <ModalContainer>
            <Modal 
                title="Ipad Keys" 
                show={isOpen} 
                onClose={close}
                footerMessage="Keys are used exclusively to sign-in to the GameSheet Scoring App for iPad"
            >
                {loading && (
                    <span>loading...</span>
                )}
                {!loading && !!apiKeys.length && apiKeys.map(k => (
                    <div key={k.id}>
                        <Card.Card 
                            size="sm" 
                            handleClick={() => setShowCard(prev => ({ ...prev, [k.id]: !prev[k.id] }))}
                        >
                            <Card.CardContentContainer
                                child={<span style={{ paddingRight: "10px" }}>{k.description}</span>}
                                expandedChild={<IpadKeyValue><span className="bold">Key:</span> <span className="code">{k.value}</span></IpadKeyValue>}
                                showExpanded={showCard[k.id]}
                            />
                            <Card.CardIconContainer showDownArrow={!showCard[k.id]} showUpArrow={showCard[k.id]} />
                        </Card.Card>
                    </div>
                ))}
                {!loading && !apiKeys.length && <>
                    <EmptyNotice>No iPad Keys Found</EmptyNotice>
                </>}
                
            </Modal>
        </ModalContainer>
    )
}