import { RosterProps } from "."
import { Title } from "@gamesheet/ui";
import { EntityCard } from "@/components/pure/EntityCard";
import * as Grid from "@/layouts/GridItems";
import styled from "styled-components";
import { useCallback } from "react";
import Avatar from "@/assets/images/blank_avatar.svg"
import { useAppState } from "@/state/app/useAppState";
import { addImgSizeToUrl } from "@/libs/addImgSizeToUrl";
import { RosterCoach, RosterPlayer } from "@/state/data/useTeamRoster/types";

const StyledPlayerCard = styled.div`
margin-top: 15px;

&:hover {
    cursor: pointer;
    .entityCard {
        border-bottom: 2px solid ${(props)=>props.theme.primaryColor};
    }
}

.entityCard {
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.2);
    border-radius: 5px;
    border-bottom: 2px solid #000;

    img {
        border-radius: 4px 0 0 4px;
    }
}
`

const StyledRosterGrid = styled.div`

display: grid;
grid-template-columns: repeat(6, 1fr);
gap: 15px;

`

type VerticalRosterProps = Omit<RosterProps, "layout">;

export function PositionedRoster({ roster, onClickCoach=()=>{}, onClickPlayer=()=>{} }:VerticalRosterProps){

    const app = useAppState()
    
    const leftWing = roster.skaters.filter(player => player.position === "left_wing");
    const center = roster.skaters.filter(player => player.position === "center");
    const rightWing = roster.skaters.filter(player => player.position === "right_wing");
    const defense = roster.skaters.filter(player => player.position === "defence" || player.position === "transition");
    const usedPlayers = [...leftWing, ...center, ...rightWing, ...defense];
    const players = roster.skaters.filter(player => !usedPlayers.includes(player));

    const goalies = roster.goalies;

    const PlayerCard = useCallback(({ player }:{ player:RosterPlayer }) => {
        return <StyledPlayerCard>
            <EntityCard 
                image={player.photo ? addImgSizeToUrl(player.photo, 96) : Avatar} 
                title={`${player.firstName} ${player.lastName}`}
                subtitle={app.helpers.translatePosition(player.position, true)}
                stat={`#${player.jersey}`}
                substat={app.helpers.translateDuty(player.duty)}
            />
        </StyledPlayerCard>
    }, [])

    const CoachCard = useCallback(({ coach }:{ coach:RosterCoach }) => {
        return <StyledPlayerCard>
            <EntityCard 
                title={`${coach.firstName} ${coach.lastName}`}
                subtitle={app.helpers.translatePosition(coach.position, true)}
            />
        </StyledPlayerCard>
    }, [])

    return <StyledRosterGrid>
        <Grid.Blank column={1} cspan={6} row={1}>
            <Title bartype="bar" text="Players" size="h4" variant="inverted"/>
            <div style={{ display: 'grid', columnGap:15, gridTemplateColumns: "repeat(3, 1fr)" }}>
                { players.map(player => <PlayerCard player={player} /> )}
            </div>
        </Grid.Blank>

        <Grid.Blank column={1} cspan={2} row={2}>
            <Title bartype="bar" text="Left Wing" size="h5" variant="muted"/>
            { leftWing.map(player => <PlayerCard player={player} /> )}
        </Grid.Blank>

        <Grid.Blank column={3} cspan={2} row={2}>
            <Title bartype="bar" text="Center" size="h5" variant="muted"/>
            { center.map(player => <PlayerCard player={player} /> )}
        </Grid.Blank>

        <Grid.Blank column={5} cspan={2} row={2}>
            <Title bartype="bar" text="Right Wing" size="h5" variant="muted"/>
            { rightWing.map(player => <PlayerCard player={player} /> )}
        </Grid.Blank>

        <Grid.Blank column={1} cspan={4} row={3}>
            <Title bartype="bar" text="Defense" size="h5" variant="muted"/>
            <div style={{ display: 'grid', columnGap:15, gridTemplateColumns: "repeat(2, 1fr)" }}>
                { defense.map(player => <PlayerCard player={player} /> )}
            </div>
        </Grid.Blank>

        <Grid.Blank column={5} cspan={2} row={3}>
            <Title bartype="bar" text="Goalies" size="h5" variant="muted"/>
            { goalies.map(goalie => <PlayerCard player={goalie} /> )}
        </Grid.Blank>

        <Grid.Blank column={1} cspan={6} row={4}>
            <Title bartype="bar" text="Coaches" size="h4" variant="inverted"/>
            <div style={{ display: 'grid', columnGap:15, gridTemplateColumns: "repeat(2, 1fr)" }}>
                { roster.coaches.map(coach => <CoachCard coach={coach} /> )}
            </div>
        </Grid.Blank>

    </StyledRosterGrid>

}