import { collection, Firestore, onSnapshot, query, DocumentReference, doc, getDoc, Unsubscribe, orderBy } from "firebase/firestore";
import { type } from "os";
import { useEffect } from "react";
import { atom, selector, useRecoilValue, useSetRecoilState } from "recoil"
import { useAppState } from "@/state/app/useAppState";
import { Game } from "./types";

type TeamEventReference = {
    id: string;
    type: string;
    data: DocumentReference<Game>
}

export type TeamEvent = Omit<TeamEventReference, "data"> & Game

type TeamEventsData = {
    data: TeamEvent[],
    lastUpdated: number,
}

type TeamEventStore = Omit<TeamEventsData, "data"> & {
    data: { 
        [key:string]: TeamEvent
    }
}

const teamEventsDataState = atom<TeamEventStore>({
    key: 'teamEventsDataState',
    default: {
        data: {},
        lastUpdated: 0,
    },
});

const teamGamesDataState = selector<TeamEventsData>({
    key: 'teamGamesDataState',
    get: ({get}) => {
        
        const data = get(teamEventsDataState)
        const events = Object.values(data.data).sort((a,b) => a.ScheduledStartTime?.Time.seconds - b.ScheduledStartTime?.Time.seconds)

        return {
            ...data,
            data: events.filter((event) => event.type === 'game')
        }
    }
})

const teamLiveGamesDataState = selector<TeamEventsData>({
    key: 'teamLiveGamesDataState',
    get: ({get}) => {
        const data = get(teamGamesDataState)
        return {
            ...data,
            data: data.data.filter((game) => ['inProgress','unofficialFinal'].includes(game.status))
        }
    }
})

const teamScheduledGamesDataState = selector<TeamEventsData>({
    key: 'teamScheduledGamesDataState',
    get: ({get}) => {
        const data = get(teamGamesDataState)
        return {
            ...data,
            data: data.data.filter((game) => ['scheduled','cancelled','postponed'].includes(game.status) )
        }
    }
})

const teamCompletedGameDataState = selector<TeamEventsData>({
    key: 'teamCompletedGameDataState',
    get: ({get}) => {
        const data = get(teamGamesDataState)
        return {
            ...data,
            data: data.data.filter((game) => ['final','completed'].includes(game.status))
        }
    }
})

const nextFutureScheduleGame = selector<TeamEvent>({
    key: 'nextFutureScheduleGame',
    get: ({get}) => {
        const futureGames = get(teamScheduledGamesDataState).data.filter(game => {
            const now = Date.now()
            const gameStart = game.ScheduledStartTime.Time.seconds * 1000
            return gameStart > now
        })
        return futureGames[0]
    }
})

export function useTeamGame(id:string|undefined){
    
    const games = useRecoilValue(teamGamesDataState)
    
    if(!id) return undefined;

    return games.data.find((game) => game.id == id)

}

export function useTeamEventsData(){
    
    const data = useRecoilValue(teamEventsDataState)
    const games = useRecoilValue(teamGamesDataState)
    const liveGames = useRecoilValue(teamLiveGamesDataState)
    const scheduledGames = useRecoilValue(teamScheduledGamesDataState)
    const completedGames = useRecoilValue(teamCompletedGameDataState)
    const nextGame = useRecoilValue(nextFutureScheduleGame)

    return {
        onChange: data.lastUpdated,
        all: () => Object.values(data.data),
        
        liveGames: (num?:number) =>  !num ? liveGames.data : liveGames.data.slice(0,num),
        scheduledGames: (num?:number) => !num ? scheduledGames.data : scheduledGames.data.slice(0,num),
        completedGames: (num?:number) => !num ? completedGames.data : completedGames.data.slice(0,num),

        nextGame,
        games: games.data
    }
}

export function useReadTeamEventsData(){
    
    const app = useAppState()
    const setTeamEventsData = useSetRecoilState(teamEventsDataState)
    const subscriptions = new Map<string, Unsubscribe>()

    useEffect(() => {
        
        // we need a team selected to read events
        if(!app.selectedTeam) return;
        
        const q = query(collection(app.connections.firestore, "teams", app.selectedTeam, "events"))
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            
            querySnapshot.docs.map((doc) => ({ 
                id: doc.id, 
                ...doc.data() 
            }) as TeamEventReference).forEach((event) => {
                
                const unsub = onSnapshot(event.data, (doc) => {
                    
                    if(!doc.exists()) return;
                    
                    setTeamEventsData((state) => ({
                        data: {
                            ...state.data,
                            [event.id]: {
                                type: event.type,
                                ...doc.data() as Game
                            }
                        },
                        lastUpdated: Date.now(),
                    }));

                    app.loading.complete('events')

                })

                !!querySnapshot.docs.length || subscriptions.set(event.id, unsub)
                
            });

            app.loading.complete('events')
            
        })

        return () => {
            setTeamEventsData({ data: {}, lastUpdated: 0 });
            subscriptions.forEach((unsub) => unsub())
            unsubscribe()
        }

    }, [ app.selectedTeam ])

}