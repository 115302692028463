import { Logo, Menu } from "@gamesheet/ui"
import styled from "styled-components"

import ScheduleIcon from '@mui/icons-material/DateRange';
import RosterIcon from '@mui/icons-material/Groups';
import StatsIcon from '@mui/icons-material/Scoreboard';
import MessagesIcon from '@mui/icons-material/Comment';

import { useEffect, useState } from "react";
import { useAppState } from "@/state/app/useAppState";
import { NavLink, useParams } from "react-router-dom";
import { useDeviceState } from "@/state/device/useDeviceState";
import { NavButton } from "@/components/pure/NavButton";

const StyledBottomNavigation = styled.div`
width: 100vw;
height: 100px;
background-color: ${props => props.theme.primaryColor};
color: ${props => props.theme.textOnSecondaryColor};
position: fixed;
bottom: 0;
left: 0;
z-index: 1000;
box-shadow: 0 -5px 5px 0px rgba(0,0,0,0.2);

.navigation-logo {
    margin-top: -10px;
    border-radius: 5px 5px 0 0;
    box-shadow: 0px -5px 5px 0px rgba(0,0,0,0.2);
    
    img {
        border-radius: 5px 5px 0 0;
    }
}
`

const StyledTopNavigation = styled.div`
display: none;
width: 100%;
height: 73px;
color: ${props => props.theme.textOnSecondaryColor};
position: fixed;
top: 87px;
z-index: 50;
border-radius: 0 0 5px 5px;

.inner-navigation {
    border-radius: 0 0 5px 5px;
    margin: 0 auto;
    max-width: 1280px;
    box-shadow: 0 5px 5px 0px rgba(0,0,0,0.2);
}
`

const InnerNavigation = styled.div.attrs({
    className: "inner-navigation"
})`
display:flex;
width: 100%;
justify-content: space-between;
height: 73px;
background-color: ${props => props.theme.secondaryColor};
`

const Tabs = styled.div.attrs({
    className: "tabs"
})`
flex: 2;
display: flex;
align-items: center;
z-index: 75;
`

const Tab = styled.div.attrs({
    className: "tab"
})`
width: 50%;
text-align: center;
font-size: 0.8rem;
z-index: 75;

a { 
    color: ${props => props.theme.textOnSecondaryColor};
    text-decoration: none;
}

a.active, a:hover { 
    color: ${props => props.theme.primaryColor};
    text-decoration: none;
}
`

const NavigationLogo = styled.div.attrs({
    className: "navigation-logo"
})`
flex: 1;
max-width: 100px;
text-align: center;
z-index: 1000;
background-color: ${props => props.theme.secondaryColor};

img {
    max-height: 90%;
}

`

export function MobileTabs(){
    
    const app = useAppState()
    const device = useDeviceState()
    const Navigation = device.isMobile ? StyledBottomNavigation : StyledTopNavigation;

    const leftButtons = app.navigation.leftMobileItems()
    const rightButtons = app.navigation.rightMobileItems()
    const iconButton = app.navigation.iconMobileItem()

    useEffect(() => {
        app.menu.close();
    }, [])

    return <>
        <Navigation>
            <InnerNavigation>
                {device.isMobile && <>
                    <Tabs>
                        {leftButtons.map((button, index) => (
                            <Tab key={index}>
                                <NavButton button={button} />
                            </Tab>
                        ))}
                    </Tabs>
                    <NavigationLogo onClick={()=>iconButton?.to && app.navigate(iconButton.to)}>
                        {/* <Logo logo="primary" color="white" contrast="darkbg" size="h4"  /> */}
                        <img src="https://res.cloudinary.com/crunchbase-production/image/upload/c_lpad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/bvfuxfbujmdkxmq26wda" />
                    </NavigationLogo>
                </>}
                <Tabs>
                    {rightButtons.map((button, index) => (
                        <Tab key={index}>
                            <NavButton button={button} />
                        </Tab>
                    ))}
                </Tabs>

            </InnerNavigation>

        </Navigation>

    </>
}