import "@gamesheet/ui/build/index.css"
import TagManager from 'react-gtm-module';
import Smartlook from "smartlook-client"

import { useAppState } from './state/app/useAppState';
import { LoginLayout } from "./layouts/LoginLayout";
import { LoadingLayout } from "./layouts/LoadingLayout";
import { Outlet, ScrollRestoration } from 'react-router-dom';
import { useWindowState } from './state/app/useWindowState';
import { useSetUserState, useUserState } from './state/user/useUserState';
import { Suspense, useEffect, useMemo } from 'react';
import { useTeamsCountOnLogin } from "./state/data/useTeams";

function App() {

  useSetUserState()
  useWindowState()
  useTeamsCountOnLogin()

  const app = useAppState()

  const showLoadingLayout = useMemo(() => (!app.loading.isComplete('firebaseAuth')), [ app.loading.isComplete('firebaseAuth') ])
  const showLoginLayout = useMemo(() => !app.isLoggedin, [ app.isLoggedin ])

  const shouldSetPluginData = useMemo(() => app.isLoggedin && app.activeUser?.uid && app.activeUser?.email, [app.activeUser?.uid, app.activeUser?.email, app.isLoggedin])
  useEffect(() => {
    
    if (!shouldSetPluginData) {
      return
    }

    const user = app.activeUser!
    TagManager.dataLayer({ dataLayer: { userId: user.uid, userRole: "manager" }})
    Smartlook.identify(user.uid, { email: user.email!, userRole: "manager" })

  }, [shouldSetPluginData])

  if (showLoadingLayout) {
    return <LoadingLayout />
  }

  if (showLoginLayout) {
    return <LoginLayout />
  }

  return <>
    <Suspense fallback={<LoadingLayout />}>
      <Outlet />
    </Suspense>
  </>;

}

export default App;

// 