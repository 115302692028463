import { useTeamRoster } from "@/state/data/useTeamRoster/useTeamRoster";
import { RosterOption } from "./types";
import { useCallback, useMemo, useState, useEffect } from "react";
import { useSeasonsData } from "@/state/data/useSeasonTeams";
import { useLockedRosters } from "@/state/data/useTeamRoster/useLockedRosters";

export function useRosterSelect() {

    const defaultRosterOption = useDefaultRosterOption();
    const { tick: lockedRostersChanged, lockedRosters } = useLockedRosters()
    
    // selected roster
    const [selectedRoster, _setSelectedRoster] = useState<RosterOption>(defaultRosterOption)
    const setSelectedRoster = useCallback((teamId: string) => {
        const selectedRoster = rosterOptions.find((team) => team.teamId == teamId)
        _setSelectedRoster(selectedRoster || defaultRosterOption)
    }, [ lockedRostersChanged ])

    useEffect(() => {
        
        // if selected roster is locked, set to default
        if (!selectedRoster.isLocked) {
            _setSelectedRoster(defaultRosterOption)
        }   

    }, [ JSON.stringify(defaultRosterOption) ])

    // create list of roster options
    const rosterOptions = useMemo(() => [
        defaultRosterOption,
        ...lockedRosters.map((team): RosterOption => ({
            ...team,
            teamId: team.id,
            title: "(LOCKED) " + team.title,
            isLocked: true
        }))
    ], [ lockedRostersChanged, JSON.stringify(defaultRosterOption) ])

    return {
        selected: selectedRoster,
        options: rosterOptions,
        isLocked: selectedRoster.isLocked,
        lockedRosters,
        setSelectedRoster
    }

}

function useDefaultRosterOption(): RosterOption {

    const seasons = useSeasonsData()
    const activeRoster = useTeamRoster()

    return useMemo(() => {
        
        const firstMaliableSeason = seasons.firstMaliable
        const seasonId = firstMaliableSeason?.season.id.toString() || "";
        const teamId = firstMaliableSeason?.id || "";

        const baseOption = { 
            teamId: "0", 
            title: "Active Roster", 
            seasonTitle: "", 
            seasonId, 
            divisionTitle: "", 
            divisionId: "", 
            displayTeamId: teamId, 
            isLocked: false 
        };

        // If hasUnlockedRoster is true, use activeRoster values
        if (seasons.hasUnlockedRoster) {
            return {
                ...baseOption,
                ...activeRoster,
            };
        }
        
        // Otherwise, set empty values
        return {
            ...baseOption,
            players: [], 
            coaches: [], 
            skaters: [], 
            goalies: []
        };

    }, [
        JSON.stringify(activeRoster), 
        JSON.stringify(seasons),
        seasons.hasMaliableRoster
    ]);

}