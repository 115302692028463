import { RosterPlayer } from "@/state/data/useTeamRoster/types";
import { InvitationPlayer } from "../types";

export const ConvertInvitationPlayerToRosterPlayer = (teamId: number, player: InvitationPlayer): RosterPlayer => ({
    ...player,
    rosterLocked: false,
    position: "",
    teamId: `${teamId}`,
    id: `${player.id}`,
    jersey: `${player.number}`,
    status: "",
    duty: "",
    birthDate: {
        Time: '',
        Valid: false
    },
    birthDay: '',
    birthMonth: '',
    birthYear: '',
    weight: '',
    height: '',
    shotHand: '',
    country: '',
    province: '',
    homeTown: '',
    committedTo: '',
    draftedBy: '',
    bio: ''
})