import { Button, Title } from "@gamesheet/ui";
import * as Grid from "@/layouts/GridItems";
import { useAppState } from "@/state/app/useAppState";
import PlayerForm from "@/forms/PlayerForm";
import { useEffect, useState, useCallback, useMemo } from "react";
import { usePlayerData } from "@/state/data/usePlayerData";
import { useParams } from "react-router";
import { PageLoading } from "@/components/pure/PageLoading";
import { Navigate } from "react-router-dom";
import { useAppNavigation } from "@/hooks/useAppNavigation";
import { useSeasonsData } from "@/state/data/useSeasonTeams";
import { useTeamRoster } from "@/state/data/useTeamRoster/useTeamRoster";
import { usePageState } from "@/state/page/usePageState";

export function TeamRosterPlayerEdit(){

    const page = usePageState('team-player-edit', {
        rememberScroll: false
    })
    
    const app = useAppState()
    const params = useParams()
    const playerId = params.id
    const isNew = playerId == "new"
    const roster = useTeamRoster()
    const { formData, setFormData, submit } = usePlayerData(playerId)
    const [ loading, setLoading ] = useState(false)
    const { navigate, destination, hasNavigation, handleNavigation } = useAppNavigation();
    const seasons = useSeasonsData()
    const showActions = seasons.hasUnlockedRoster
    const formValid = useMemo(() => { return (formData.firstName.length > 1 && formData.lastName.length > 1) }, [ formData.firstName, formData.lastName ])

    const onSavePlayer = useCallback( () => {
        if (formValid) {
            setLoading(true)
            submit(onPlayerSaved)
        }
    }, [ submit ])

    const onPlayerSaved = () => {
        setLoading(false)
        const updateFunc = playerId == "new" ? roster.addPlayer : roster.updatePlayer
        updateFunc({
            firstName: formData.firstName,
            lastName: formData.lastName,
            id: formData.id,
            position: formData.position,
            rosterLocked: formData.rosterLocked,
            teamId: formData.teamId,
            bio: formData.biography || "",
            birthDate: {
                Time: "",
                Valid: false,
            },
            birthDay: formData.birthDay || "",
            birthMonth: formData.birthMonth || "",
            birthYear: formData.birthYear || "",
            height: formData.height || "",
            weight: formData.weight || "",
            committedTo: formData.committedTo || "",
            country: formData.country || "",
            draftedBy: formData.draftedBy || "",
            duty: formData.duty || "",
            homeTown: formData.hometown || "",
            jersey: formData.jersey || "",
            photo: "",
            province: formData.province || "",
            shotHand: formData.shotHand as "right" | "left" || "",
            status: formData.status || "",
        })
        navigate(`/roster/team`)
    }
    
    useEffect(() => {
        app.ui('leftTray').set(<Button gs-style="solid" onClick={handleNavigation(`/roster/team`)}>Back</Button>)
        if (showActions) {
            if (formValid) {
                app.ui('rightTray').set(<Button gs-style="solid" onClick={onSavePlayer}>{ playerId == "new" ? "Create" : "Save" }</Button>)
            } else {
                app.ui('rightTray').set(<Button gs-style="solid" style={{ backgroundColor: '#c9c9c9' }} onClick={onSavePlayer}>{ playerId == "new" ? "Create" : "Save" }</Button>)
            }
        }

        return () => {
            app.ui('leftTray').clear()
            app.ui('rightTray').clear()
        }
    }, [ onSavePlayer ])

    if (hasNavigation) {
        return <Navigate to={destination} />
    }

    if(loading){
        return <PageLoading />
    }

    return (<>
        <Grid.Blank column={1} row={1}>
            
            <PlayerForm formData={formData} setFormData={setFormData} />
            { showActions && !isNew && <Button variant="danger" width="block" onClick={handleNavigation(`/roster/player/${playerId}/delete`)}>Remove {formData.firstName} {formData.lastName}</Button> }
        </Grid.Blank>
    </>)
}