import { Title } from "@gamesheet/ui";
import { Roster } from "@/components/views/Roster";
import * as Grid from "@/layouts/GridItems";

export function SeasonRosterPage(){
    
    const roster = { coaches: [], skaters: [], goalies: [], players: [] }

    return <Roster roster={roster} />

}