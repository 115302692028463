import { useAppState } from "@/state/app/useAppState";
import { useCallback, useEffect, useState } from "react";
import { LockedRoster, RosterCoach, RosterPlayer } from "./types";
import { useSeasonsData } from "../useSeasonTeams";
import { collection, getDocs, query } from "firebase/firestore";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { LockedRostersState, rawLockedRostersState } from "./atom.lockedrosters";
import { useRosterService } from "@/services/useRosterService";
import { rawRosterState, rosterState } from "./atom.rosterstate";
import { StoredRoster } from "@/services/useRosterService/types";

export function useReadLockedRosterData() {

  const app = useAppState()
  const seasons = useSeasonsData()
  const RosterService = useRosterService()
  const setRoster = useSetRecoilState(rawLockedRostersState)
  const lockedTeams = seasons.teams.filter((team) => team.rosterLocked)

  useEffect(() => {

    setRoster(r => ({}))

    lockedTeams.map((team) => {
      console.log(team)
      const onRosterChange = (partial: Partial<StoredRoster>) => {

        setRoster(r => ({ 
          ...r, 
          [team.id]: { 
            ...r[team.id], 
            ...team,
            seasonTitle: team.season.title,
            seasonId: team.season.id.toString(),
            divisionTitle: team.division.title,
            divisionId: team.division.id.toString(),
            displayTeamId: team.id,
            ...partial 
          } 
        }))
      }

      return RosterService.listenToSeasonTeamRoster(
        app.connections.firestore,
        onRosterChange,
        app.selectedTeam,
        team.id
      )

    })

  }, [app.selectedTeam])

}