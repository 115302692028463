import { UserState, useUserState } from "@/state/user/useUserState";
import { LoginStep } from "./step.login";
import { useLoginFlow } from "./state/useLoginFlow";
import { LoginFlowPath } from "./types";
import { SignupStep } from "./step.signup";
import { useCallback } from "react";
import { Button } from "@gamesheet/ui";
import { styled } from "styled-components";
import { UiTrays } from "@/layouts/Trays";
import { useAppState } from "@/state/app/useAppState";
import { AcceptInvitationFlow } from "../AcceptInvitationFlow";
import { AcceptInvitationFlowPath } from "../AcceptInvitationFlow/types";
import { InvitationPage } from "@/pages/user.invitation";

const MobileLoginFlowContainer = styled.div`
position: fixed;    
left: 16px;
right: 16px;
bottom: 16px;
top: 16px;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;

.signup-step {
    height: calc(100% - 32px);
    overflow-y: scroll;
}
`

const DesktopFlowContainer = styled.div`
height: 100%;
.signup-step {
    width: 400px;
    max-height: calc(100% - 32px);
}
`

const LoginFlowButtons = styled.div`
display: flex;
flex-direction: column;
margin-top: 4px;
width: 100%;

gap: 4px;
position: relative;

.mobile-tray, .desktop-tray {
    position: relative;
    background: transparent;
    height: inherit;
    top: inherit;
    left: inherit;
    right: inherit;
    bottom: inherit;
    min-height: 0;
    padding: 0;

    display: flex;
    flex-direction: column;

    .inner {

        width: 100%;
        display: flex;
        gap: 4px;
        flex-direction: column-reverse;
        padding: 0;

        > div {
            width: 100%;
            float: none;
            padding: 0;
        }
    }

    .button {
        width: 100%;
        margin: 0;
    }
}

.button {
    width: 100%;
}
`

type LoginFlowButtonProps = {
    onClick: () => void
    label: string
}

function LoginFlowButton({ onClick, label }:LoginFlowButtonProps){
    return <Button 
        onClick={onClick} 
        type="button" 
        gs-style="solid" 
        variant="inverted"
    >
            {label}
    </Button>
}

export function LoginFlow(){

    const app = useAppState()
    const user = useUserState()
    const flow = useLoginFlow(LoginFlowPath.Login)
    const LoginFlowContainer = app.layout.showMobile ? MobileLoginFlowContainer : DesktopFlowContainer

    const handleSignOut = async () => {
        try {
          await user.SignOut();
          flow.reset();
        } catch (error) {
          console.error('Sign out error:', error);
        }
      };

    const path = useCallback(() => {
        switch(flow.path){
            default:
            case LoginFlowPath.Login: return <LoginStep user={user} onSignup={flow.useSignupPath}/>
            case LoginFlowPath.Signup: return <SignupStep />
            case LoginFlowPath.ForgotPassword: return <></>
            case LoginFlowPath.ResetPassword: return <></>
        }
    }, [ flow.path, user ])

    const buttons = useCallback(() => {
        switch(flow.path){
            case LoginFlowPath.Login: return <>
                {/* <LoginFlowButton onClick={flow.useForgotPasswordPath} label="Forgot Password" />
                <LoginFlowButton onClick={flow.useResetPasswordPath} label="Reset Password" /> */}
            </>

            case LoginFlowPath.Signup: return <>
                <LoginFlowButton onClick={flow.reset} label="Return to Login" />
            </>

            case LoginFlowPath.ForgotPassword: return <></>
            case LoginFlowPath.ResetPassword: return <></>
        }
    }, [ flow.path ])

    return <LoginFlowContainer>
        { path()}
        <LoginFlowButtons>
            <UiTrays />
            { buttons() }
        </LoginFlowButtons>
    </LoginFlowContainer>

}