import { useRecoilValue, useResetRecoilState } from "recoil";
import { useCallback, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { AcceptInvitationFlowPath, RegistrationData } from "@/flows/AcceptInvitationFlow/types";
import { useInvitationCodeService } from "@/flows/AcceptInvitationFlow/services/useInvitationCodeService";
import { AcceptInvitationFlowState } from "./atom.AcceptInvitationFlowState";
import { AcceptInvitationFlowScreenState } from "./selector.AcceptInvitationFlowScreenState";
import { useManagedFlow } from "@/hooks/useManagedFlow";
import { createUserWithEmailAndPassword, updateProfile } from "firebase/auth";
import { auth } from "@/libs/firebase";
import { SignupFormState } from "@/forms/SignupForm"
import { useAppState } from "@/state/app/useAppState";
import { useResetForm } from "@/forms/hooks/useResetForm";
import { SampleTeamState } from "./selector.SampleTeam";
import { TeamData } from "@/state/data/useTeams";
import { MergePreviewDataState } from "./selector.MergedPreviewWithRoster";
import { LoginFlowPath } from "@/flows/LoginFlow/types";
import { useLoginFlow } from "@/flows/LoginFlow/state/useLoginFlow";

export function useAcceptInvitationFlow(path: AcceptInvitationFlowPath) {

    const app = useAppState();
    const navigate = useNavigate();
    const reset = useResetRecoilState(AcceptInvitationFlowState)
    const resetSignupForm = useResetForm('signup')
    const { next, prev, state, setState } = useManagedFlow(AcceptInvitationFlowState, path)
    const invitationCodeService = useInvitationCodeService();
    const screenState = useRecoilValue(AcceptInvitationFlowScreenState);
    const sampleTeam = useRecoilValue(SampleTeamState);
    const mergedPreview = useRecoilValue(MergePreviewDataState);
    const loginFlow = useLoginFlow(LoginFlowPath.Login)

    // events
    useEffect(() => {
        app.events.add("AcceptInvitationFlow.Complete", reset)
    }, [ reset ])
        
    return {

        ...state,
        ...screenState,

        get isLoading() { return state.loading || invitationCodeService.isFetching },

        controls: {
            next,
            prev
        },

        readInvitationCode: useCallback(async (code: string) => {
            //clear any previous errors
            setState(state => ({ ...state, invitation: undefined, error: "" }));

            // get code details
            const response = await invitationCodeService.loadCodeDetails(code)
            const userRoles = response.data?.userAlreadyHasTeamRoles && Object.values(response.data.userAlreadyHasTeamRoles)
            const allRolesExist = !!userRoles?.length && userRoles.every((value: boolean) => value);

            if (allRolesExist) {
                setState(state => ({ ...state, invitation: undefined, error: "You're already a member of this team" }));
                return
            }

            // save errir
            if (response.status === 'error') {
                console.log(response.errors)
                setState(state => ({ ...state, invitation: undefined, error: response.errors.map((e: any) => e.message).join() }));
                return
            }

            if (!response.data.teams.length) {
                setState(state => ({ ...state, invitation: undefined, error: "No Teams in Invitation" }));
                return
            }

            // set invitation and continue
            setState(state => ({ ...state, invitation: response.data }));
            next();

        }, [next, invitationCodeService.loadCodeDetails, setState]),

        confirmCodeCorrect: useCallback(async () => {

            const invite = state.invitation!;
            if (!invite?.teams || !invite?.userAlreadyHasTeamRoles) {
                return;
            }

            const unclaimedTeams = invite.teams.filter(team => {
                return !invite.userAlreadyHasTeamRoles[team.id]
            })

            if (unclaimedTeams.length > 0) {
                return next()
            }

            app.events.trigger("AcceptInvitationFlow.Complete")

        }, [next, state.invitation]),

        previewMerge: useCallback(async () => {

            // start preview merge request
            const teamId = state.invitation?.teams![0].id
            const prototeamId = state.prototeamOptions.linkedTeam?.id

            if (!teamId || !prototeamId) {
                console.log("missing team id or prototeamId", teamId, prototeamId)
                return;
            }

            const response = await invitationCodeService.loadMergePreview(teamId, prototeamId)

            // save error
            if (response.status === 'error') {
                console.log(response.errors)
                setState(state => ({ ...state, mergedPreview: undefined, error: response.errors.map((e: any) => e.message).join() }));
                return
            }

            // set mergedPreview and continue
            setState(state => ({ ...state, rawMergedData: response.data["preview-invitation"] }));
            next();

        }, [next, setState, invitationCodeService.loadMergePreview, state.invitation, state.prototeamOptions]),

        acceptInvitationCode: useCallback(async () => {

            // accept invitation
            const code = state.invitation?.invitation?.code
            const teamId = state.invitation?.teams![0].id
            const strategy = state.prototeamOptions.strategy
            const prototeamId = state.prototeamOptions.linkedTeam?.id || ""

            if (!code || !teamId || !strategy) {
                console.log("missing code, team id, or strategy", code, teamId, strategy)
                return;
            }

            if (strategy === "link" && !prototeamId) {
                console.log("missing prototeamId", prototeamId)
                return;
            }

            const response = await invitationCodeService.acceptInvitation(code, strategy, teamId, prototeamId)
            if (response.status === 'error') {
                console.error(response.errors);
                setState(state => ({ ...state, error: response.errors.map((e: any) => e.message).join() }));
                return;
            };

            app.events.trigger("AcceptInvitationFlow.ExistingUser.Complete")
            app.events.trigger("AcceptInvitationFlow.Complete")
            app.setAcceptedInvite(true)
            loginFlow.reset()

            // return user back to where they came from
            navigate(-1);

        }, [navigate, next, invitationCodeService.acceptInvitation, state.invitation, state.prototeamOptions]),

        registerNewAccount: useCallback(async (formdata: SignupFormState) => {

            //check it valid
            for (const field of Object.values(formdata)) {
                if (!field.value.match(field.test)) {
                    console.log("Form Validation Failed")
                    return;
                }
            }

            const data: RegistrationData = {
                firstName: formdata.firstName.value,
                lastName: formdata.lastName.value,
                email: formdata.email.value,
                password: formdata.password.value,
            }

            setState(state => ({ ...state, loading: true }))

            // create account in gamesheet
            const response = await invitationCodeService.createAccount(state.invitationCode, data.firstName, data.lastName, data.email, data.password)
            if (response.status === 'error') {
                console.error(response);
                setState(state => ({ ...state, loading: false }))
                return;
            };

            // create firebaser account
            const user = await createUserWithEmailAndPassword(auth, data.email, data.password).then(async (userCredential) => {
                return userCredential.user
            })
            user && await updateProfile(user, { displayName: `${data.firstName} ${data.lastName}` })

            // update firebase user id
            const fuidResponse = await invitationCodeService.setFirebaseUserId(data.email, user.uid)
            if (fuidResponse.status === 'error') {
                console.error(fuidResponse);
                setState(state => ({ ...state, loading: false }))
                return;
            };

            app.events.trigger("AcceptInvitationFlow.NewUser.Complete")
            app.events.trigger("AcceptInvitationFlow.Complete")
            app.setAcceptedInvite(true)

            resetSignupForm()
            setState(state => ({ ...state, loading: false }))

        }, [state.invitation, setState, resetSignupForm, invitationCodeService.setFirebaseUserId]),

        setStrategy: (strategy: "link" | "add" | "") => {
            console.log(strategy)
            setState(s => ({ ...s, strategy }))
        },

        setLinkedTeam: (team: TeamData | undefined) => {
            console.log(team)
            setState(state => ({ ...state, linkedTeam: team }))
        },

        sampleTeam,

        mergedPreview,

        strategy: state.prototeamOptions.strategy,

    }

}