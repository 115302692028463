import { Button, Title } from "@gamesheet/ui";
import { useEffect, useMemo } from "react";
import { useAppState } from "@/state/app/useAppState";
import { styled } from "styled-components";
import { Roster as RosterView } from "@/components/views/Roster";
import { LayoutGridContainer } from "@/layouts/GridItems";
import { InsetTextBlock } from "./components/InsetTextBlock";
import { DetailedTeamPreview } from "@/components/pure/DetailedTeamPreview";
import { RosterWrapper } from "./components/RosterWrapper";
import { TeamData } from "@/state/data/useTeams";
import { Roster } from "@/state/data/useTeamRoster/types";

const DesktopTeamPreview = styled.div`

.team-selector {
    
    padding-top: 0;

    .team-logo {
        padding-left: 8px;
        img {
            width: 96px;
            height: 96px;
        }
    }

    .team-title {
        .title {
            font-size: 1.75rem;
        }
        .subtitle {
            font-size: 1.1rem;
        }
    }
}`

const MobileTeamPreview = styled.div`

.team-selector {
    
    margin-top: 15px;
    margin-bottom: 15px;

    .team-logo {
        padding-left: 8px;
    }
}`

type PreviewMergeProps = {
    logo: string;
    title: string;
    prototeam: TeamData;
    roster: Roster;
    season: {
        title: string;
    },
    division: {
        title: string;
    },
    onAccept: () => void;
    onBack: () => void;
}

export function PreviewMerge({ logo, title, prototeam, season, division, onBack, onAccept, roster }:PreviewMergeProps){
    
    const app = useAppState()
    const StyledTeamPreview = app.layout.showDesktop ? DesktopTeamPreview : MobileTeamPreview;

    const BtnBack = useMemo(() => <Button type="button" onClick={onBack}>Back</Button>, [ onBack ])
    useEffect(() => app.ui('leftTray').set(BtnBack, app.layout.showMobile), [ BtnBack, app.layout.showMobile ])

    const BtnNext = useMemo(() => <Button type="button" onClick={onAccept}>Accept Invitation</Button>, [ onAccept ])
    useEffect(() => app.ui('rightTray').set(BtnNext, app.layout.showMobile), [ BtnNext, app.layout.showMobile ])

    return <div>
        
        <Title bartype="plain" text="" size="h3">
            <InsetTextBlock>You are linking {title} with {prototeam.title}</InsetTextBlock>
        </Title>

        <InsetTextBlock style={{fontSize: "0.85rem", fontWeight: "500", lineHeight: ".9rem"}}>
            These rosters will be combined into one and any future roster changes will affect both rosters.
        </InsetTextBlock>

        <StyledTeamPreview>
            <DetailedTeamPreview
                onClick={() => {}}
                logo={logo || ""}
                title={title || ""}
                season={season?.title || ""}
                division={division?.title || ""}
                $stretch={app.layout.showMobile}
            />
        </StyledTeamPreview>

        { !!roster.players.length && <>
            <Title bartype="divider" text="Roster" size="h4"/>
            <LayoutGridContainer style={{ marginBottom: 15 }}>
                <RosterWrapper>
                    <RosterView roster={roster} showTitles={false} />
                </RosterWrapper>
            </LayoutGridContainer>
        </>}
        
        { (app.layout.showDesktop) && <>
            <hr/>
            <div style={{ display:'flex', justifyContent:'space-between' }}>
                {BtnBack}
                {BtnNext}
            </div>
        </>}

    </div>

}