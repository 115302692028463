import { config as devConfig } from "./config.dev"
import { config as qaConfig } from "./config.qa"
import { config as betaConfig } from "./config.beta"
import { config as prodConfig } from "./config.prod"
import { Config } from "./type"

const branch = process.env.REACT_APP_BRANCH || "main"
let config:Config = devConfig;

switch (true) {
    
    case /(feat\/.*)|(fix\/.*)|(qa.*)/i.test(branch): 
        console.log("using qa config based on ", branch.match(/(feat\/.*)|(fix\/.*)|(qa.*)/i));
        config = qaConfig; break;
    
    case /^beta/i.test(branch): 
        console.log("using beta config based on ", branch.match(/beta/i));    
        config = betaConfig; break;
    
    case /(release\/.*)|main|demo|staging/i.test(branch): 
        console.log("using prod config based on ", branch.match(/(release\/.*)|main|demo|staging/i));
        config = prodConfig; break;
    
    default: 
        config = devConfig; break;
}

console.log("config", config);
console.log("environment", process.env);

export default {
    ...config,
    app: process.env.REACT_APP_NAME || "app",
};