import logoPlaceholder from "@/assets/images/blank_jersey.svg"
import SelectionArrowIcon from '@mui/icons-material/KeyboardArrowDown';
import React, { forwardRef, useRef } from "react";
import { styled } from "styled-components"

const TeamSelector = styled.div.attrs({
    className: "team-selector"
})<{ $stretch?: boolean }>`
display: flex;
align-items: center;
justify-content: space-between;
gap: 15px;
width: ${p => p.$stretch ? "100%" : "auto"};


border-radius: 8px;
padding-right: 8px;
cursor: pointer;
user-select: none;
padding: 15px 8px;

small {
    opacity: 0.6;
}
`

const TeamLogo = styled.div.attrs({
    className: "team-logo"
})`
align-items: center;
padding-left: 15px;

img {
    display: block;
    height: 40px;
}
`
const TeamTitle = styled.div.attrs({
    className: "team-title"
})`
flex-basis: 100%;
flex: 1;

.subtitle{
    display: block;
    opacity: 0.6;
    font-style: italic;
}
`

const Selector = styled.div.attrs({
    className: "selector"
}) <{ state: string }>`
min-width: 40px;
cursor: pointer;

svg {
    transform: ${props => props.state === "open" ? "rotate(180deg)" : "rotate(0deg)"};
    transition: transform 0.2s ease-in-out;
}
`

type TeamPreviewProps = {
    onClick: () => void;
    logo: string;
    title: string;
    subtitle: string;
    showSelector?: boolean;
    selectorState?: string;
    $stretch?: boolean;
}

export const TeamPreview = forwardRef((props:TeamPreviewProps, ref:React.ForwardedRef<HTMLDivElement>) => {

    const { onClick, logo, title, subtitle, $stretch, selectorState, showSelector=false } = props;

    return <TeamSelector onClick={onClick} ref={ref} $stretch={$stretch}>
        <TeamLogo>
            <img src={logo ? `${logo}/256` : logoPlaceholder} />
        </TeamLogo>
        <TeamTitle>
            <span className="title">{title}</span>
            <span className="subtitle">{subtitle && <small>{subtitle}</small>}</span>
        </TeamTitle>
        { showSelector && <Selector state={selectorState || ""}>
            <SelectionArrowIcon />
        </Selector> }
    </TeamSelector>

})