import { PropsWithChildren } from "react";
import styled from 'styled-components';
import bg2 from '@/assets/images/bg2.jpg'
import { LoginFlow } from "@/flows/LoginFlow";

const StyledLoginLayout = styled.div`

> div {
    background-size: cover;
    background-position: right;
    background-attachment: fixed;
    background-image: url(${bg2});
    min-height: 100vh;
    
    opacity: 1;
    transition: opacity 1s ease-in-out;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
    // font-size: calc(10px + 2vmin);
    // font-style: italic;
    // font-variant: small-caps;
    // color: black;
    gap: 48px;

}
`

// add a firebase login form to the login screen

export function LoginLayout(props: PropsWithChildren<{}>) {

    return (<StyledLoginLayout>
        <div>
            <LoginFlow />
        </div>
    </StyledLoginLayout>

    );
}