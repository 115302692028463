import { useRecoilValue } from "recoil"
import { LockedRostersState } from "./atom.lockedrosters"
import { useCallback, useEffect, useMemo, useState } from "react"

export function useLockedRosters() {

    const rosters = useRecoilValue(LockedRostersState)
    const [tick, setTick] = useState(0)

    useEffect(() => {
        setTick(tick + 1)
    }, [JSON.stringify(rosters)])

    const getBySeasonTeamId = useCallback((teamId: string) => {
        return rosters[teamId]
    }, [rosters])

    const lockedRosters = useMemo(() => Object.values(rosters), [rosters])

    return {
        tick,
        lockedRosters,
        getBySeasonTeamId
    }

}