import { useRecoilState, useRecoilValue } from "recoil";
import { FormItems, FormsState, useFormStateResponse } from "./useCreateForm";
import { ChangeEvent, useCallback, useMemo } from "react";

export function useForm<T extends FormItems>(id:string): useFormStateResponse<T> & { setState: (newState:T) => void } {

    const [formsState, setFormsState] = useRecoilState(FormsState);
    
    const formState = useMemo(() => formsState[id], [ formsState[id] ])
    const currentFormState = useMemo(() => formState?.currentState || {}, [ formState?.currentState ])

    const setFormState = useCallback((newState:T) => {
        setFormsState({
            ...formsState,
            [id]: {
                ...formsState[id],
                currentState: newState,
            }
        })
    }, [ formsState, setFormsState ])

    const onChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        
        let { name, value }:{ name:string, value:string } = e.target;
        name.startsWith(id) && (name = name.replace(`${id}-`, ``))
        
        if( currentFormState[name] === undefined ){
            console.error(`FormState.${name} does not exist`)
            return
        }
        
        setFormState({ 
            ...currentFormState, 
            [name]: {
                value: value,
                error: currentFormState[name].error,
                test: currentFormState[name].test,
                valid: value.match(currentFormState[name].test) ? true : false,
            }
        } as T);
    }, [ currentFormState, setFormState ]);

    return {
        setState: setFormState,
        state: currentFormState as T,
        onChange
    }

}