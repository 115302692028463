import { useState } from "react";

export function useAppNavigation() {
  
  const [destination, navigate] = useState<string>("");
  const hasNavigation = destination !== "";

  const handleNavigation = (path: string) => {
    return () => navigate(path);
  };

  return {
    navigate,
    destination,
    hasNavigation,
    handleNavigation
  }

}
