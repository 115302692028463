import { useCallback, useEffect, useState } from "react";
import { atom, selector, useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { firestore } from "@/libs/firebase";
import { collection, query, getDocs, onSnapshot, where, getCountFromServer } from "firebase/firestore";
import { useAppState } from "@/state/app/useAppState";
import { asyncFilter } from "@/libs/asyncFilter";
import { SeasonTeamData, SeasonTeamsData, useSeasonsData } from "./useSeasonTeams";
import { RosterCoach, RosterPlayer } from "./useTeamRoster/types";

export type TeamData = {
    id: string;
    title: string;
    logo: string;
    club: string;
}

export type TeamsData = {
    data: TeamData[],
    loaded: boolean,
    lastUpdated: number,
}

// imported into useSeasonTeams!
export const rawTeamsDataState = atom<TeamsData>({
    key: 'rawTeamsDataState',
    default: {
        data: [],
        loaded: false,
        lastUpdated: 0,
    },
});

export const teamsDataState = selector<TeamsData>({
    key: 'teamsDataState',
    get: ({get}) => {
        
        const rawProtoTeams = get(rawTeamsDataState)
        const rawSeasonTeams = get(rawSeasonTeamsDataState)
        
        const teams = []
        for (const prototeam of rawProtoTeams.data) {
            
            const seasonTeams = rawSeasonTeams[prototeam.id]
            if(!seasonTeams) continue;
            if(!seasonTeams.length) continue;
            teams.push(prototeam)
            
        }
        
        return {
            data: teams,
            loaded: rawProtoTeams.loaded,
            lastUpdated: rawProtoTeams.lastUpdated,
        }

    },
});

export const rawSeasonTeamsDataState = atom<{[key:string]:SeasonTeamsData}>({
    key: 'rawSeasonTeamsDataState',
    default: {},
});

export function useReadTeamsData() {

    const app = useAppState()
    const [ rawTeamsData, setRawTeamsData ] = useRecoilState(rawTeamsDataState)
    const [ rawSeasonTeamsData, setRawSeasonTeamsData ] = useRecoilState(rawSeasonTeamsDataState)

    useEffect(() => {

        // only logged in user will be able to read teams data
        if(!app.activeUser) return;
        
        const subscriptions:Array<() => void> = []
        
        const q = query(collection(firestore, "teams"), where("users","array-contains", app.activeUser.email))
        const sub = onSnapshot(q, async (querySnapshot) => {
            
            let data = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }) as TeamData)
            
            for (const team of data) {

                const q2 = query(
                    collection(firestore, `teams/${team.id}/seasonteams`), 
                    where("deletedAt.Valid","==",false),
                )

                const sub = onSnapshot(q2, async (querySnapshot2) => {
                    
                    const seasonTeamData = querySnapshot2.docs
                        .map((doc) => ({ id: doc.id, ...doc.data() }) as SeasonTeamData)
                        .filter(team => !team.season.archived)

                    setRawSeasonTeamsData((old) => {
                        return {
                            ...old,
                            [team.id]: seasonTeamData
                        }
                    })
                    setRawTeamsData(old => {
                        return {
                            ...old,
                            lastUpdated: Date.now(),
                        }
                    })

                })
                subscriptions.push(sub)

            }

            // })
            
            setRawTeamsData({
                data,
                loaded: true,
                lastUpdated: Date.now(),
            })
            
            let selectedTeam:string = app.selectedTeam

            // if a new prototeam is added, select it
            querySnapshot.docChanges().forEach((change) => {
                
                if (change.type === "added") {
                    selectedTeam = change.doc.id
                }

                if(change.type === "removed" && app.selectedTeam === change.doc.id){
                    selectedTeam = ""
                }

            });
            
            // if no team is selected, select the first one
            if(!selectedTeam && data[0]){
                selectedTeam = data[0].id
            }

            app.setSelectedTeam(selectedTeam)

        })
        subscriptions.push(sub)

        return () => {
            subscriptions.forEach(unsub => unsub())
        }

    }, [ app.activeUser ])

    useEffect(() => {

        const loaded = rawTeamsData.loaded;
        const prototeams = rawTeamsData.data.length;
        const loadedPrototeamSeasonTeams = Object.keys(rawSeasonTeamsData).length;

        if(!loaded){
            return
        }

        if(prototeams > loadedPrototeamSeasonTeams){
            return
        }

        app.loading.complete('teams')

    }, [ rawTeamsData.loaded, rawTeamsData.data.length, JSON.stringify(rawSeasonTeamsData) ])

}

export function useTeamsCountOnLogin() {
    const app = useAppState();
  
    useEffect(() => {
      if (!app.activeUser) return;
  
      const fetchTeamsCount = async () => {
        try {
          const q = query(collection(firestore, 'teams'), where('users', 'array-contains', app.activeUser?.email) );
          const querySnapshot = await getDocs(q);
          const data = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          
          app.teams.setCheckedOnLogin(true);
        } catch (error) {
          console.error('Error fetching teams count:', error);
          
          app.teams.setCheckedOnLogin(true);
        }
      };
  
      fetchTeamsCount();
    }, [ app.activeUser ]);
  
    return app.teams.count; // You can return the count if needed
}

export function useTeamsData() {
    
    const { lastUpdated, data } = useRecoilValue(teamsDataState)
    const { prototeams } = useSeasonsData()

    const getPlaysIn = useCallback((teamId: string) => {
        
        const seasonTeams = prototeams.find((prototeam) => prototeam.id === teamId)?.seasonTeams

        if( seasonTeams?.length ){
            
            for(let i = seasonTeams.length - 1; i >= 0; i--){
                try {
                    const season = seasonTeams[i].season.title
                    const division = seasonTeams[i].division.title
                    return `${division}, ${season}`
                } catch (e) {
                    continue;
                }
            }

        }

        return null
    }, [ JSON.stringify(prototeams) ])

    const getSeasonIds = (teamId: string) => {
        const seasonTeams = prototeams.find((prototeam) => prototeam.id === teamId)?.seasonTeams;
      
        if (seasonTeams?.length) {
          const seasonIds = seasonTeams.map((seasonTeam) => seasonTeam.season.id).filter(Boolean);
          return seasonIds;
        }
      
        return [];
      };
      

    return {
        onChange: lastUpdated,
        all: () => data,
        teams: data,
        getSeasonIds,
        getPlaysIn
    }

}