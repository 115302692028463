import styled from "styled-components"
import { useAppState } from "@/state/app/useAppState"

const StyledMobileTray = styled.div.attrs<{ $expanded: boolean }>({
    className: "mobile-tray"
})`
position: fixed;
top: ${({ $expanded }) => $expanded ? '60px': 'calc(100% - 198px)'};
bottom: 98px;
left: 0;
right: 0;
padding-bottom: 10px;
padding-top: 10px;
min-height: 100px;
z-index: 3300;

background-color: rgba(250,250,250, 0);
display: flex;
justify-content: space-between;
align-items: flex-end;

div.inner {
    display: contents;
}
`

const StyledDesktopTray = styled.div.attrs<{ $expanded: boolean }>({
    className: "desktop-tray"
})`
position: fixed;
bottom: 0;
width: 100%;
left: 0;
right: 0;
z-index: 100;
background: ${p => p.theme.secondaryColor};
display: flex;
justify-content: space-between;
align-items: flex-end;

div.inner {
    width: 1280px;
    margin: 0 auto;
    padding: 15px 0 15px 0;
}
`

const LeftTray = styled.div.attrs({
    className: "left-tray"
})`
padding: 0 0 0 15px;
float: left;

> .button {
    margin-right: 15px;
    margin-bottom: 5px;
}
`

const RightTray = styled.div.attrs({
    className: "right-tray"
})`
padding: 0 15px 0 0;
float: right;

> .button {
    margin-left: 15px;
    margin-bottom: 5px;
}
`

export function UiTrays(){

    const app = useAppState()
    const events = app.events
    const StyledTray = app.device.isMobile ? StyledMobileTray : StyledDesktopTray
    const leftTray = app.ui('leftTray').get()
    const rightTray = app.ui('rightTray').get()

    const showTray = leftTray || rightTray

    return <StyledTray $expanded={app.menu.isOpen} onClick={()=>{ events.trigger('close-more-menu') }}>
        { showTray && <div className="inner">
            <LeftTray>{leftTray}</LeftTray>
            <RightTray>{rightTray}</RightTray>
        </div> }
    </StyledTray>

}