import { Config } from "./type";
import { config as defaultConfig } from "./config.default";

const qa:Config = {
    ...defaultConfig,
    name: "QA",
    apps: {
        statsWidget: "https://qa.gamesheetstats.com",
        leagueDashboard: "https://qa.gamesheet.app",
    },
    firebase: {
        apiKey: "AIzaSyBiCavmlIowAvuyxIlJ6DeEHoFvGOuZkuE",
        authDomain: "gamesheet-v4-testing-stack.firebaseapp.com",
        projectId: "gamesheet-v4-testing-stack",
        storageBucket: "gamesheet-v4-testing-stack.appspot.com",
        messagingSenderId: "358351777959",
        appId: "1:358351777959:web:384f890d4dfb6d59036b82",
        measurementId: "G-2B8GJ5V3EC"
    }
}

const qaLocal:Config = {
    ...qa,
    name: "QA Local",
    settings:{
        ...qa.settings,
        showDumpStateButton: true,
    },
    gateways: {
        data: "https://gateway-data-5weo5hsb7q-nn.a.run.app",
        events: "https://gateway-events-5weo5hsb7q-nn.a.run.app",
        auth: "https://gateway-authserver-5weo5hsb7q-nn.a.run.app",
    },
    services: {
        assetsApi: "https://assetsapi-5weo5hsb7q-nn.a.run.app",
        registryApi: "https://registrysync-v4-5weo5hsb7q-nn.a.run.app",
        scoresheetExportApi: "https://scoresheet-service-5weo5hsb7q-nn.a.run.app",
    },
    apps: {
        ...qa.apps
    },
}

export const config = (process.env.REACT_APP_LOCAL === "true") ? qaLocal : qa;