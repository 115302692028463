import { collection, getDocs, query } from "firebase/firestore";
import { atom, selector, useRecoilValue, useSetRecoilState } from "recoil";
import { firestore } from "@/libs/firebase";
import { useAppState } from "@/state/app/useAppState";
import { rawSeasonTeamsDataState, teamsDataState } from "./useTeams";
import { appState } from "@/state/app/app.state";

export type SeasonTeamData = {
    id: string;
    title: string;
    rosterLocked: boolean;
    deletedAt: {
        Time: string;
        Valid: boolean;
    },
    division: {
        id: number;
        title: string;
    },
    season: {
        id: number;
        title: string;
        archived: boolean;
    }
}

export type SeasonTeamsData = SeasonTeamData[]

const seasonTeamsDataState = selector({
    key: 'seasonTeamsDataState',
    get: async ({get}) => {
        
        const prototeams = get(teamsDataState)
        const rawSeasonTeams = get(rawSeasonTeamsDataState)

        const seasonTeams = prototeams.data.map(async (team) => {
            
            const seasonTeams = rawSeasonTeams[team.id]
            return {
                ...team,
                seasonTeams
            }

        })

        return Promise.all(seasonTeams)

    }
})

const seasonsDataState = selector({
    key: 'seasonsDataState',
    get: ({get}) => {
        
        const app = get(appState)
        const data = get(seasonTeamsDataState)
        const prototeam = data.find(team => team.id === app.selectedTeam)

        if(!prototeam) return {
            seasons: [],
            divisions: [],
            teams: []
        };

        return {
            teams: prototeam.seasonTeams,
            divisions: prototeam.seasonTeams.map((data) => {

                const { season, division, ...team } = data

                return {
                    season,
                    ...division,
                    team
                }

            }),
            // invert the team to be a property of the season
            seasons: prototeam.seasonTeams.map((data) => {

                const { season, division, ...team } = data

                return {
                    ...season,
                    division,
                    team
                }

            }),
        }
    }
})

export function useSeasonsData(){

    const prototeamData = useRecoilValue(seasonTeamsDataState)
    const data = useRecoilValue(seasonsDataState)

    const firstUnlocked = data.teams.find(team => team.rosterLocked !== true)
    const firstUnarchived = data.teams.find(team => team.season.archived !== true)
    const firstMaliable = data.teams.find(team => team.season.archived !== true && team.rosterLocked !== true)

    const withUnlockedRosters = data.teams.filter(team => team.rosterLocked !== true)
    const withUnarchivedSeasons = data.teams.filter(team => team.season.archived !== true)
    const withMaliableRosters = data.teams.filter(team => !team.rosterLocked && !team.season.archived)

    return {
        teams: data.teams,
        seasons: data.seasons,
        lastUpdated: Date.now(),
        
        hasUnlockedRoster: !!firstUnlocked,
        firstUnlocked,
        withUnlockedRosters,

        hasUnarchivedSeason: !!firstUnarchived,
        firstUnarchived,
        withUnarchivedSeasons,

        hasMaliableRoster: !!firstMaliable,
        firstMaliable,
        withMaliableRosters,

        hasLockedRoster: withUnlockedRosters.length !== data.teams.length,
        hasArchivedSeason: !firstUnarchived,

        allSeasonsRostersUnlocked: withUnlockedRosters.length === data.teams.length,

        prototeams: prototeamData,
        dashboard: firstUnlocked || data.teams[0]

    }

}