import { Button } from "@gamesheet/ui";
import styled from 'styled-components';
import { useAppState } from "@/state/app/useAppState";
import bg2 from '@/assets/images/bg2.jpg'
import { useCallback } from "react";
import { useUserState } from "@/state/user/useUserState";

const StyledErrorPage = styled.div`
  background-size: cover;
  background-position: right;
  background-attachment: fixed;
  background-image: url(${bg2});
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: black;
  gap: 15px;
  position: relative;
`;

const Overlay = styled.div`
  position: absolute;
  top: 2.5%;
  left: 4%;
  width: 92%;
  height: 95%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(10px);
  border-radius: 8px;
  padding: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  z-index: 1;
  text-font: 'Rubik', Arial, sans-serif;
`;

const HeaderDiv = styled.div`
  flex-grow: 2;
  text-align: center;
  font-family: 'Rubik', Arial, sans-serif;
  font-size: 32px;
  font-weight: bold;
  letter-spacing: 1px;
  justify-content: flex-end;
  display: flex;
  flex-direction: column;
`

const TopDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 8;
  text-align: center;
  font-family: 'Rubik', Arial, sans-serif;
`;

const MiddleDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-grow: 10;
  text-align: center;
  font-family: 'Rubik', Arial, sans-serif;
`;

const BottomDiv = styled.div`
  flex-grow: 1;
`;

const BottomButton = styled(Button)`
  width: 100%;
  height: 40%;
  margin-top: 8px;
`;

export function TooManyTeams() {
    const app = useAppState()
    const user = useUserState()

    const switchToDashboard = useCallback(async () => {
        const otp = await user.GetOtp()
        const url = `${app.config.apps.leagueDashboard}/associations?otp=${otp}`
        window.location.href = url

    }, [])

    const handleSignOut = async () => {
      try {
        await user.SignOut();
        app.navigate('/');
      } catch (error) {
        console.error('Sign out error:', error); // Log error
      }
    };

  return (
    <StyledErrorPage>
      <Overlay>
        <HeaderDiv>
          Accessing the <br /> Team Dashboard
        </HeaderDiv>
        <TopDiv>
          <div>The Team Dashboard is exclusively designed for users holding the Team Manager account type. This account type is primarily intended for coaches, managers, and other team staff. If your account does not fall under this category or if you have access to more than 8 teams, we regret to inform you that the Team Dashboard is not suitable for your usage.</div>
        </TopDiv>
        <MiddleDiv>
          <div>No need to worry, though! Our regular Dashboard application is fully equipped to meet your needs and can be accessed by clicking the button below.</div>
        </MiddleDiv>
        <BottomDiv>
            <BottomButton onClick={switchToDashboard}>Go To Dashboard</BottomButton>
            <BottomButton onClick={handleSignOut}>Sign Out</BottomButton>
        </BottomDiv>
      </Overlay>
    </StyledErrorPage>
  );
}