import { PositionedRoster } from "./PositionedRoster";
import { VerticalRoster } from "./VerticalRoster";
import { Roster as RosterType, RosterCoach, RosterPlayer } from "@/state/data/useTeamRoster/types";

export type RosterProps = {
    roster: RosterType;
    layout?: "vertical" | "positioned";
    onClickCoach?: (coach: RosterCoach) => void;
    onClickPlayer?: (player: RosterPlayer) => void;
    showTitles?: boolean;
};

export function Roster({ roster, onClickCoach=()=>{}, onClickPlayer=()=>{}, layout="vertical", showTitles=true }:RosterProps){

    switch(layout){
        default:
        case "vertical": return <VerticalRoster roster={roster} onClickCoach={onClickCoach} onClickPlayer={onClickPlayer} showTitles={showTitles} />
        case "positioned": return <PositionedRoster roster={roster} onClickCoach={onClickCoach} onClickPlayer={onClickPlayer} showTitles={showTitles} />
    }

}