import { ActionType, ReducerAction, TeamRosterLocalState } from "./types";

export const TeamRosterReducer = (state: TeamRosterLocalState, action: ReducerAction): TeamRosterLocalState => {

    switch (action.type) {
        case ActionType.TOGGLE_MENU_STATE:
            return {
                ...state,
                menuOpen: action.state?.menuOpen || !state.menuOpen
            };
        case ActionType.SET_ACTIVE_MENU:
            return {
                ...state,
                activeMenu: action.state?.activeMenu || state.activeMenu
            };
        case ActionType.TOGGLE_ROSTER_SELECT:
            return {
                ...state,
                rosterSelectOpen: !state.rosterSelectOpen
            };

    }

    return state;

}