import styled from "styled-components"

export const LayoutGridContainer = styled.div.attrs({
    className: "layout-grid-ctr"
})`
display: grid;
grid-auto-columns: auto;
grid-auto-rows: auto;
gap: 15px 15px;
z-index: 10;
`

const LayoutGridItem = styled.div<{ column:number, cspan?: number, row: number, rspan?: number  }>`
border-radius: 5px;
grid-column-start: ${(props)=>props.column};
grid-column-end: ${(props)=>props.cspan && props.cspan + props.column || "auto"};
grid-row-start: ${(props)=>props.row};
grid-row-end: ${(props)=>props.rspan && props.rspan + props.row || "auto"};
`

export const Blank = styled(LayoutGridItem)``

export const Primary = styled(LayoutGridItem)`
background-color: ${(props)=>props.theme.primaryColor};
color: ${(props)=>props.theme.textOnPrimaryColor};
`

export const Secondary = styled(LayoutGridItem)`
background-color: ${(props)=>props.theme.secondaryColor};
color: ${(props)=>props.theme.textOnSecondaryColor};
`

export const Info = styled(LayoutGridItem)`
background-color: ${(props)=>props.theme.info};
color: #FFF;
`

export const Success = styled(LayoutGridItem)`
background-color: ${(props)=>props.theme.success};
`

export const Danger = styled(LayoutGridItem)`
background-color: ${(props)=>props.theme.danger};
`
export const Muted = styled(LayoutGridItem)`
background-color: ${(props)=>props.theme.muted};
`

export const Inverted = styled(LayoutGridItem)`
background-color: ${(props)=>props.theme.inverted};
color: ${(props)=>props.theme.primaryColor};
`