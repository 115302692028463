import { useEffect, useState, useCallback } from "react";
import { send } from "@/libs/cloudflare";
import { useAppState } from "@/state/app/useAppState";
import { useUserState } from "@/state/user/useUserState";
import { useSeasonsData } from "./useSeasonTeams";
import { VerifiedUser } from "@mui/icons-material";
import { auth } from "@/libs/firebase";
import { firebaseUserState } from "@/state/user/useSetFirebaseAuth";
import { useTeamRoster } from "./useTeamRoster/useTeamRoster";

export type PlayerFormData = {
    id: string;
    firstName: string;
    lastName: string;
    jersey: string;
    status: string;
    position: string;
    duty: string;
    birthMonth?: string;
    birthDay?: string;
    birthYear?: string;
    weight?: string;
    height?: string;
    shotHand?: string;
    country?: string;
    province?: string;
    hometown?: string;
    draftedBy?: string;
    committedTo?: string;
    biography?: string;
    rosterLocked: boolean;
    teamId: string;
};

export const usePlayerData = (playerId?: string) => {

    const roster = useTeamRoster()
    const service = usePlayerRosterEventService()
    const isNewPlayer = playerId === "new";
    const seasons = useSeasonsData();

    const [formData, setFormData] = useState<PlayerFormData>({
        id: "",
        firstName: "",
        lastName: "",
        status: "Regular",
        jersey: "",
        duty: "",
        position: "",
        birthMonth: "",
        birthDay: "",
        birthYear: "",
        weight: "",
        height: "",
        shotHand: "",
        country: "",
        province: "",
        hometown: "",
        draftedBy: "",
        committedTo: "",
        biography: "",
        rosterLocked: false,
        teamId: "",
    });

    const submit = useCallback( (onComplete?: () => void) => {
        
        // TODO: validate form data
        const { birthMonth, birthDay, birthYear, rosterLocked, teamId, ...player } = formData;

        // This prevents the user from being able to send a request to create or update a player when:
        // - there is no unlocked seasonteam in this prototeam OR
        // - the player does not exist in any unlocked seasonteam in this prototeam
        if (!seasons.hasUnlockedRoster || rosterLocked || formData.firstName === "" || formData.lastName === "") return; // rosterLocked might be redundant

        const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        var birthMonthNum = (months.findIndex((name) => name.toLowerCase() === formData.birthMonth?.toLowerCase()) + 1).toString();

        if (formData.birthYear === "" || formData.birthDay === "" || birthMonthNum === "0") {
            formData.birthYear = ""
            formData.birthDay = ""
            birthMonthNum = ""
        }

        const playerData = {
            ...player,
            birthDate: `${formData.birthYear}-${birthMonthNum}-${formData.birthDay}`,
        }
        
        const func = isNewPlayer ? "create" : "update";
        
        const team_id = isNewPlayer ? seasons.firstUnlocked!.id : teamId

        return service[func](team_id, playerData).then(onComplete)
        
    }, [formData, seasons.hasUnlockedRoster, seasons.firstUnlocked]);

    const remove = useCallback( (onComplete?: () => void) => {

        const { rosterLocked } = formData;
        
        // This prevents the user from being able to send a request to create or update a player when:
        // - there is no unlocked seasonteam in this prototeam OR
        // - the player does not exist in any unlocked seasonteam in this prototeam
        if (!seasons.hasUnlockedRoster || rosterLocked) return; // rosterLocked might be redundant
        // We should return an error message here instead that says that you can't edit an unlocked team.
        // Alternatively, we could no show the delete button when there is no unlocked team.

        // guard against bad data
        if (!playerId || isNewPlayer){
            return;
        }

        return service.remove(seasons.firstUnlocked!.id, playerId).then(onComplete)
    }, [formData, seasons.hasUnlockedRoster, seasons.firstUnlocked])

    useEffect(() => {

        
        if(!playerId || playerId === "new") return;

        const player = roster.players.find((player) => player.id == playerId);

        if(!player) return;
        setFormData({
            id: playerId,
            firstName: player.firstName,
            lastName: player.lastName,
            status: player.status,
            jersey: player.jersey,
            duty: player.duty,
            position: player.position,
            birthMonth: player.birthMonth,
            birthDay: player.birthDay,
            birthYear: player.birthYear,
            weight: player.weight,
            height: player.height,
            shotHand: player.shotHand,
            country: player.country,
            province: player.province,
            hometown: player.homeTown,
            draftedBy: player.draftedBy,
            committedTo: player.committedTo,
            biography: player.bio,
            rosterLocked: player.rosterLocked,
            teamId: player.teamId
        });
    }, [playerId]);



    return {
        formData,
        setFormData,
        submit,
        remove,
    }

}

type PlayerRosterEvent = "prototeam-player-create" | "prototeam-player-update" | "prototeam-player-remove" | "prototeam-player-add" | "prototeam-player-restore";
function usePlayerRosterEventService() {
    
    const app = useAppState();
    const user = useUserState();
    const seasons = useSeasonsData();

    async function send(event: PlayerRosterEvent, team_id: string, playerData: Partial<PlayerFormData>){

        const token = await user.GetToken();
        

        const playerSendData = {
            ...playerData,
            id: parseInt(playerData.id ? playerData.id : "0"),
        }

        const teamId = parseInt(team_id)

        const body = {
            event,
            attributes: {
                schema: "player-event"
            },
            data: {
                teamId,
                player: playerSendData,
            }
        }
        console.log(body)

        // TODO: handle errors
        return fetch(`${app.config.gateways.events}/roster`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
        })
        .then((res) => res.json())
        .catch((err) => {
            console.log(err);
        });

    }

    return {
        update: (teamId:string, playerData: Partial<PlayerFormData>) => send("prototeam-player-update", teamId, playerData),
        create: (teamId:string, playerData: Partial<PlayerFormData>) => send("prototeam-player-create", teamId, playerData),
        remove: (teamId:string, playerId: string ) => send("prototeam-player-remove", teamId, { id: playerId }),
        // add: () => send("prototeam-player-add"),
        // restore: () => send("prototeam-player-restore"),
    }

}