import { Title } from "@gamesheet/ui";
import { styled } from "styled-components";

const StyledTitleRadio = styled.label`
    display: flex;
    align-items: center;
    gap: 15px;
`

type PrototeamOptionProps = {
    id: string
    selectedId: string;
    onClick: (id:string) => void;
    name: string;
    text: string;
}

export function PrototeamOption({ name, id, selectedId, onClick, text }:PrototeamOptionProps){

    return <Title bartype="bar" text="" size="h6" variant={id==selectedId?"":"muted"}>
        <StyledTitleRadio onClick={() => onClick(id)}>
            <input
                readOnly
                className="form-check-input" 
                type="radio" 
                checked={id==selectedId} 
                name={name} 
                id={id} 
            />
            <label className="form-check-label">
                {text}
            </label>
        </StyledTitleRadio>
    </Title>

}