import { Button, FormWrapper } from "@gamesheet/ui";
import { styled } from "styled-components";
import { useCallback, useState } from "react";
import { InsertInvitationCodeProps } from "./types";
import { useInvitationCodeState } from "./state/useInvitationCodeState";
import { ErrorCard } from "@/components/pure/EntityCard";

const Error = styled.span`
  color: red;
`;

const StyledInsertInvitationCode = styled.div`
width: 100%;
`

export function InsertInvitationCode({ onSubmit, error:externalError }:InsertInvitationCodeProps){

    const [error, setError] = useState<string>()
    const hasError = !!error;
    
    const [code, _setCode] = useInvitationCodeState();
    const setCode = useCallback((input:string) => { setError(""); _setCode(input) }, [ _setCode, setError ])
    
    const handleSubmit = (e: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        isValid() && onSubmit(code);
    };

    // validate
    function isValid(){
        if(code.length < 1){
            setError("Please enter a code")
            return false;
        }
        return !error;
    }

    function getErrorDescription(message:string){
        switch(message){
            case "No Teams in Invitation": return "This invitation code is not linked to any teams. Please contact the team owner for a new code.";
            case "Bad Invitation Code": return "This invitation code is invalid. Please contact the team owner for a new code.";
            default: return message;
        }
    }

    return <StyledInsertInvitationCode>

        <p>
            An Invitation or an Invitation Code is a 10 letter random code that is linked 
            to a specific team in the system.&nbsp;
            <a href="https://help.gamesheet.app/article/26-invitation-codes" target="helpscout">Learn More</a>
        </p>

        { externalError && <ErrorCard title={externalError} message={getErrorDescription(externalError)} /> }

        <FormWrapper>
      
            <form onSubmit={handleSubmit}>
                
                <div className="form-floating mt-3 mb-3">
                
                    <input 
                        required 
                        type="text" 
                        name="invitationCode" 
                        id="invitationCode" 
                        className="form-control" 
                        placeholder="Invitation Code" 
                        onChange={(e) => setCode(e.target.value)} 
                        value={code}
                    />
                    
                    <label htmlFor="invitationCode" className="floating">
                        {hasError ? <Error>{error}</Error> : `Invitation Code`}
                    </label>
                    
                </div>
            </form>

        </FormWrapper>

        <Button style={{ width:'100%' }} onClick={handleSubmit}>Continue</Button>

    </StyledInsertInvitationCode>

}