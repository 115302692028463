import { Title } from "@gamesheet/ui";
import { InsertInvitationCode } from "./step.InsertInvitationCode";
import { useAcceptInvitationFlow } from "./state/useAcceptInvitationFlow";
import { PageLoading } from "@/components/pure/PageLoading";
import { PreviewTeam } from "./step.PreviewTeam";
import { PreviewMerge } from "./step.PreviewMerge";
import { PrototeamOptions } from "./step.PrototeamOptions";
import { AcceptInvitationFlowPath } from "./types";
import { styled } from "styled-components";
import { useAppState } from "@/state/app/useAppState";
import { RegisterNewAccount } from "./step.RegisterNewAccount";

const MobileAcceptInvitationFlow = styled.div``

const DesktopAcceptInvitationFlow = styled.div`
max-width: 500px;
margin-left: auto;
margin-right: auto;
`

type Props = {
    path: AcceptInvitationFlowPath
    onDone?: () => void
}

export function AcceptInvitationFlow({ path }: Props) {

    const app = useAppState()
    const StyledAcceptInvitationFlow = app.layout.showDesktop ? DesktopAcceptInvitationFlow : MobileAcceptInvitationFlow
    const flow = useAcceptInvitationFlow(path);

    if (flow.isLoading) {
        return <PageLoading />
    }

    return <StyledAcceptInvitationFlow>

        {/* Insert Invitation Code */}
        {flow.showCodeInput && <InsertInvitationCode
            onSubmit={flow.readInvitationCode}
            error={flow.error}
        />}

        {/* Show Team Preview */}
        {flow.showTeamPreview && flow.sampleTeam && <PreviewTeam
            logo={flow.sampleTeam.logo}
            title={flow.sampleTeam.title}
            season={flow.sampleTeam.season}
            division={flow.sampleTeam.division}
            onBack={flow.controls.prev}
            onAccept={flow.confirmCodeCorrect}
            roster={{
                players: flow.sampleTeam.roster,
                skaters: flow.sampleTeam.roster,
                coaches: [],
                goalies: [],
            }}
        />}

        {flow.showAccountRegistration && <RegisterNewAccount
            onBack={flow.controls.prev}
            onAccept={flow.registerNewAccount}
        />}

        {flow.showPrototeamOptions && flow.sampleTeam && <PrototeamOptions
            title={flow.sampleTeam.title}
            season={flow.sampleTeam.season}
            onBack={flow.controls.prev}
            onAccept={flow.strategy === "add" ? flow.acceptInvitationCode : flow.previewMerge}
        />}

        {flow.showSelectedOptionSummary && flow.mergedPreview && <PreviewMerge
            logo={flow.mergedPreview.logo}
            title={flow.mergedPreview.title}
            prototeam={flow.mergedPreview.prototeam}
            season={flow.mergedPreview.season}
            division={flow.mergedPreview.division}
            onBack={flow.controls.prev}
            onAccept={flow.acceptInvitationCode}
            roster={{
                players: flow.mergedPreview.roster,
                coaches: [],
                goalies: [],
                skaters: []
            }}
        />}

    </StyledAcceptInvitationFlow>

}