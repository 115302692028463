import { Dayjs } from "dayjs";
import { useMemo, useState } from "react";
import styled from "styled-components";
import defaultHomeJersey from "@/assets/images/home_jersey.svg";
import defaultVisitorJersey from "@/assets/images/visitor_jersey.svg";
import { addImgSizeToUrl } from "@/libs/addImgSizeToUrl";
import { BreakPoints } from "@/libs/breakpoints";
import { useAppState } from "@/state/app/useAppState"
import { TeamEvent } from "@/state/data/useTeamEvents";

export const StyledTeamScoreBoard = styled.div`
  display: flex;
  justify-content: center;
  font-family: 'Rubik';
  margin: 0 auto;
  margin-top: 2rem;
  max-width: 1140px;
  width: 100%;
  padding: 0.5rem 0rem;
  flex-direction: row;

  .flip {
    svg { 
      transform: rotate(180deg);
    }
  }

  .scores-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-basis: 33%;

    @media only screen and (max-width: ${BreakPoints.md}) {
      padding-bottom: 24px;
    }
  }

  .mobile-roster-container {
    padding: 2rem 0 0 0;
  }

  .divider {
    font-size: 80px;
    color: #000;
    font-weight: 900;
    padding: 0 1.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 24px;

    @media only screen and (max-width: ${BreakPoints.sm}) {
      padding: 0;
      font-size: 22px;
    }
  }

  .scoreboard-gametime {
    height: 34px;
    min-width: 125px;
    font-family: "Kapra Neue Pro";
    font-style: normal;
    font-weight: 00;
    font-size: 40px;
    line-height: 48px;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    justify-content: center;
    @media only screen and (max-width: ${BreakPoints.md}) {
      font-size: 24px;
    }
  }

  .scoreboard-date {
    font-family: "Rubik";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    margin-top: 0.5rem;
    display: flex;
    align-items: center;
    text-transform: uppercase;

    color: rgba(54, 56, 61, 0.5);

    @media only screen and (max-width: ${BreakPoints.md}) {
      font-size: 10px;
    }
  }

  .visitor-container {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: flex-end;
    text-align: right;

    /* @media only screen and (max-width: ${BreakPoints.lg}) {
      flex-direction: row;
      align-items: center;
    } */

    @media only screen and (max-width: ${BreakPoints.md}) {
      flex-direction: column-reverse;
      justify-content: flex-end;
      align-items: flex-end;
      padding-right: 0;
      flex-basis: 33%;
    }

    .division-container {
      display: flex;
      justify-content: flex-end;
      font-size: 0.875rem;
      font-weight: 400;
      @media only screen and (max-width: ${BreakPoints.md}) {
        /* justify-content: flex-end;
        flex-direction: row-reverse; */
        display: none;
      }
    }

    .team-name {
      text-align: right;
      @media only screen and (max-width: ${BreakPoints.lg}) {
        /* text-align: left;
        padding-left: 0; */
        font-size: 24px;
        line-height: 1.2;
      }

      @media only screen and (max-width: ${BreakPoints.md}) {
        /* text-align: left;
        padding-left: 0; */
        font-size: 12px;
        line-height: 14px;
      }
    }

    .type {
      margin-left: 15px;
      /* @media only screen and (max-width: ${BreakPoints.lg}) {
        text-align: left;
        margin-left: 0;
      } */
    }

    .button-container {
      left: 0;

      @media only screen and (max-width: ${BreakPoints.sm}) {
        padding-left: 1rem;
      }
      .button {
        @media only screen and (max-width: ${BreakPoints.md}) {
          font-size: 12px;
        }

        @media only screen and (max-width: ${BreakPoints.sm}) {
          font-size: 6px;
          svg {
            max-width: 6px;
          }
        }
      }
    }

    .division-name {
      opacity: 0.3;
      @media only screen and (max-width: ${BreakPoints.lg}) {
        padding-left: 1rem;
      }
    }
  }

  .home-container {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: flex-end;
    flex-direction: row-reverse;
    text-align: left;
    /* @media only screen and (max-width: ${BreakPoints.lg}) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    } */

    @media only screen and (max-width: ${BreakPoints.md}) {
      flex-direction: column-reverse;
      justify-content: flex-start;
      align-items: flex-start;
      padding-right: 0;
      flex-basis: 33%;
    }

    .division-container {
      display: flex;
      flex-direction: row-reverse;
      justify-content: flex-end;
      font-size: 0.875rem;
      @media only screen and (max-width: ${BreakPoints.md}) {
        display: none;
      }
    }

    .team-name {
      text-align: left;
      @media only screen and (max-width: ${BreakPoints.lg}) {
        /* text-align: left;
        padding-left: 0; */
        font-size: 24px;
        line-height: 1.2;
      }
      @media only screen and (max-width: ${BreakPoints.md}) {
        /* text-align: left;
        padding-left: 0; */
        font-size: 12px;
        line-height: 14px;
      }
    }

    .type {
      margin-right: 15px;
    }

    .button-container {
      right: 0;

      @media only screen and (max-width: ${BreakPoints.sm}) {
        padding-right: 1rem;
      }
      .button {
        @media only screen and (max-width: ${BreakPoints.md}) {
          font-size: 12px;
        }
        @media only screen and (max-width: ${BreakPoints.sm}) {
          font-size: 6px;
          svg {
            max-width: 6px;
          }
        }
      }
    }

    .final-score {
      /* @media only screen and (max-width: ${BreakPoints.lg}) {
        padding-top: 0rem;
      } */
    }

    .image-container {
      flex-direction: row-reverse;
    }

    .record {
      text-align: left;
    }
  }

  .division-team-record-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }

  .division-name {
    opacity: 0.3;
  }

  .team-name {
    margin: 1rem 0;
    text-transform: uppercase;
    font-size: 1.75rem;
    font-weight: 500;
    line-height: 1.2;
    @media only screen and (max-width: ${BreakPoints.lg}) {
      /* text-align: left; */
      font-size: 12px;
      line-height: 14px;
    }
  }

  .type {
    font-weight: 700;
    letter-spacing: 0.05em;
    text-transform: 500;
    text-transform: uppercase;
  }

  .image-container {
    margin: 0 15px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .record {
    font-weight: 700;
    letter-spacing: 0.5em;
    font-size: 0.875rem;
    text-align: right;

    @media only screen and (max-width: ${BreakPoints.lg}) {
      /* text-align: left; */
      font-size: 10px;
    }
  }

  .header {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: flex-end;
  }

  .header:hover {
    background-color: #f5f5f5;
    cursor: pointer;
  }
  
`;

type Team = {
    id?: string;
    title?: string;
    logo?: string;
    record?: string;
}

type HeaderProps = {
    home?: Team;
    visitor?: Team;
    date: Dayjs
    next: TeamEvent | undefined;
}

type TeamContainerProps = Team & {
    context: "home" | "visitor";
}

export function ScheduledGameHeader({ home, visitor, date, next }: HeaderProps) {

  const app = useAppState();

    return <StyledTeamScoreBoard onClick={() => app.navigate(`/boxscore/${next?.season.id}/${next?.id}`)}>
        <div className="header">
          <div className="visitor-container">
              <ScoreboardTeamContainer
                  context="visitor"
                  {...(visitor ? visitor : {})}
              />
          </div>
          <div className="divider">
              <div className="scoreboard-gametime">{date.format("h:mm A")}</div>
              <div className="scoreboard-date">{date.format("dddd MMM DD")}</div>
          </div>
          <div className="home-container">
              <ScoreboardTeamContainer
                  context="home"
                  {...(home ? home : {})}
              />
          </div>
      </div>
    </StyledTeamScoreBoard>;

}

function ScoreboardTeamContainer({ context, ...team }: TeamContainerProps) {

    const teamStatusLabel = useMemo(() => context == "home" ? "Home" : "Visitor", [context])
    const defaultJersey = useMemo(() => context == "home" ? defaultHomeJersey : defaultVisitorJersey, [context])

    return <>
        <div className="stats-container">
            <div className="division-team-record-container">
                <div className="division-container">
                    <div className="type">{teamStatusLabel}</div>
                </div>
                <div className="team-name">{team?.id ? team.title : "TBD"}</div>
                <div className="record">{team?.record && `(${team?.record})`}</div>
            </div>
        </div>
        <div className="image-container">
            <img src={team?.logo ? addImgSizeToUrl(team.logo, 256) : defaultJersey} width={128} height={128} alt={`team logo`} />
        </div>
    </>

}