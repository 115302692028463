import { RecoilState, atom } from "recoil";
import { NavigationItem } from "@/components/pure/NavButton";
import { useNestedApp } from "./useNestedApp";
import { AppFormState } from "./app.interface";

export type AppUiLocations = "leftTray" | "rightTray";

export type AppState = {
    selectedTeam: string|undefined,
    acceptedFirstInvite: boolean,
    lastChange: number,
    loaded: string[]
    nestedApps: {
        [key: string]: ReturnType<typeof useNestedApp>
    },
    teams: {
        count: number,
        checkedOnLogin: boolean,
    },
    menu: {
        isOpen: boolean,
    },
    ui: {
        [key in AppUiLocations]: JSX.Element | undefined;
    },
    navigation: NavigationItem[]
}

export const appState = atom<AppState>({
    key: "appState",
    default: {
        selectedTeam: undefined,
        acceptedFirstInvite: false,
        lastChange: 0,
        loaded: [],
        nestedApps: {},
        teams: {
            count: 0,
            checkedOnLogin: false,
        },
        menu: {
            isOpen: false,
        },
        ui: {
            leftTray: undefined,
            rightTray: undefined,
        },
        navigation: []
    },
})