import { useEffect, useState } from "react";
import { atomFamily, useRecoilState } from "recoil";
import { useAppState } from "../app/useAppState";

const PageStates = atomFamily({
    key: 'PageStates',
    default: {
        scrollY: 0
    }
});

type PageOptions = {
    rememberScroll: boolean
}

const defaultOptions:PageOptions = {
    rememberScroll: true
}

export function usePageState( pageId:string, _options?:Partial<PageOptions> ){

    const app = useAppState()
    
    // page options
    const options:PageOptions = {
        ...defaultOptions,
        ..._options
    }

    // page session (intentionally not persisted)
    const [ pageSession, setPageSession ] = useState({
        loading: true
    })

    // page global state (persisted between page loads)
    const [ page, setPageState ] = useRecoilState(PageStates(`${app.selectedTeam}-${pageId}`));

    // on page load, scroll to the last known scroll position
    useEffect(() => {
        window.scrollTo({
            top: page.scrollY,
            behavior: "instant" as ScrollBehavior
        })
        setPageSession({ loading: false })
    }, [])

    // on page unload, save the current scroll position
    useEffect(() => {
        
        const handleScroll = () => {
            setPageState({
                scrollY: window.scrollY
            })
        }
        
        options.rememberScroll && window.addEventListener('scroll', handleScroll)
        return () => {
            options.rememberScroll && window.removeEventListener('scroll', handleScroll)
        }

    }, [])

    // return the page state
    return {
        ...page,
        ...pageSession
    }

}