import { useRecoilState } from "recoil";
import { FormsState } from "./useCreateForm";
import { useCallback } from "react";

export function useResetForm( id:string ){

    const [formsState, setFormsState] = useRecoilState(FormsState);
    
    return useCallback(() => {
        
        const formState = formsState[id]
        console.log("useResetForm", id, formsState)
        formState?.initialState && setFormsState({
            ...formsState,
            [id]: {
                ...formsState[id],
                currentState: { ...formState.initialState },
            }
        })

    }, [ formsState ])
}