import { atom, useRecoilValue } from "recoil"
import { auth } from "@/libs/firebase"

import { signOut, signInWithEmailAndPassword, createUserWithEmailAndPassword, updateCurrentUser, updateProfile, setPersistence, browserLocalPersistence } from "firebase/auth"
import { firebaseUserState, useSetFirebaseAuth } from "./useSetFirebaseAuth";
import { useLoginWithOtp } from "./useLoginWithOtp";
import { useSetGameSheetUserState } from "./useGameSheetUserState";
import { getOtp } from "./getOtp";
import { useEventsState } from "@/state/events/useEventsStats";
import config from "@/config";
import { useAppState } from "../app/useAppState";
import dayjs from "@/libs/dayjs";

export const userState = atom({
    key: 'userState',
    default: {
        tokens: {
            access: "",
            refresh: "",
            roles: "",
        }
    },
})

export function useSetUserState(){

    useSetFirebaseAuth()
    useLoginWithOtp()
    useSetGameSheetUserState()

}

export type UserState = ReturnType<typeof useUserState>
export function useUserState() {

    const app = useAppState()
    const events = app.events
    const firebaseUser = useRecoilValue(firebaseUserState)
    const user = useRecoilValue(userState)

    return {
        
        displayName: firebaseUser?.displayName,
        email: firebaseUser?.email,
        photoURL: firebaseUser?.photoURL,
        tokens: user.tokens,
        isLoggedIn: !!firebaseUser,
        isRecentSignup: !!firebaseUser && dayjs().diff(dayjs(firebaseUser?.metadata.creationTime), "seconds") < 60,

        SignIn: async (email: string, password: string) => {
            
            try {
                
                await setPersistence(auth, browserLocalPersistence);
                await signInWithEmailAndPassword(auth, email, password);

            } catch (error: any) {
                
                switch (error.code) {
                    case "auth/requires-recent-login": throw "Please logout then back in first to refresh authentication"
                    case "auth/wrong-password": throw "Email or password is incorrect"
                    case "auth/user-not-found": throw "Email or password is incorrect"
                    case "auth/too-many-requests": throw "Too many login attempts, please try again later"
                }
                throw error.message

            }
        },

        SignOut: async () => {
            await signOut(auth)
            events.trigger("user:signout", user)
        },
        
        GetToken: async () => {
            return user.tokens.access
        },
        
        GetOtp: async () => {
            if (user.tokens.access) {
                return getOtp(user.tokens.access);
            }
            return "";
        },
        
        Expire: async () => {
            return fetch(`${config.gateways.auth}/auth/v4/expire-user`, { 
                method: "POST", 
                headers: { 
                    Authorization: `Bearer ${user.tokens.access}` 
                } 
            })
        }
    }

}