import { DocumentData, Query, onSnapshot } from "firebase/firestore";
import { FirestoreRosterCoach, FirestoreRosterPlayer, StoredRoster } from "./types";
import { RosterCoach, RosterPlayer } from "@/state/data/useTeamRoster/types";
import dayjs from "@/libs/dayjs"

export function listenToRoster(
    playersQuery: Query<DocumentData>,
    coachesQuery: Query<DocumentData>,
    onRosterChange: (roster:Partial<StoredRoster>) => void
) {

    let players: FirestoreRosterPlayer[] = [];
    let coaches: FirestoreRosterCoach[] = [];

    const unsubPlayers = onSnapshot(playersQuery, (querySnapshot) => {
        players = querySnapshot.docs.map((doc) => {
                
            const hasBirthdate = doc.data().birthDate.Valid
            const birthdate = dayjs(doc.data().birthDate.Time)
            
            return { 
                id: doc.id,
                ...doc.data(), 
                jersey: doc.data().number,
                birthYear: hasBirthdate ? birthdate.format('YYYY') : "",
                birthMonth: hasBirthdate ? birthdate.format('MMMM') : "",
                birthDay: hasBirthdate ? birthdate.format('DD') : "",
            } as FirestoreRosterPlayer
        
        })
        onRosterChange({ players })
    });

    // coaches
    const unsubCoaches = onSnapshot(coachesQuery, (querySnapshot) => {
        
        coaches = querySnapshot.docs.map((doc) => {
    
            return { 
                id: doc.id,
                ...doc.data(), 
            } as FirestoreRosterCoach
        
        })
        onRosterChange({ coaches })

    })

    return () => {
        unsubPlayers()
        unsubCoaches()
    }

} 