import { useCallback, useEffect } from "react";
import { RecoilState, useRecoilState } from "recoil";

export type ManagedFlow<Step extends string | number | symbol, Path extends string | number | symbol> = {
    step: Step,
    path?: Path | undefined,
    paths: {
        [key in Path]: Step[]
    }
}

export function useManagedFlow<P extends string | number | symbol, T extends ManagedFlow<any, P>>(flow: RecoilState<T>, path: P) {

    const [state, setState] = useRecoilState(flow);
    useEffect(() => { setState(s => ({ ...s, path })) }, [path])

    const next = useCallback(() => {
        if (!state.path) return;
        const path = state.paths[state.path];
        const nextStep = path[path.indexOf(state.step) + 1];
        setState(state => ({ ...state, step: nextStep }));
    }, [state.path, state.step])

    const prev = useCallback(() => {
        if (!state.path) return;
        const path = state.paths[state.path];
        const prevStep = path[path.indexOf(state.step) - 1];
        setState(state => ({ ...state, step: prevStep }));
    }, [state.path, state.step])

    return {
        next,
        prev,
        state,
        setState
    }

}