import { selector } from "recoil";
import { AcceptInvitationFlowStep as Step } from "@/flows/AcceptInvitationFlow/types";
import { AcceptInvitationFlowState } from "./atom.AcceptInvitationFlowState";

export const AcceptInvitationFlowScreenState = selector({
    key: 'AcceptInvitationFlowScreenState',
    get: ({get}) => {
        const state = get(AcceptInvitationFlowState);
        
        return {
            showCodeInput: state.step === Step.InsertInvitationCode,
            showTeamPreview: state.step === Step.PreviewTeam,
            showAccountRegistration: state.step === Step.RegisterNewAccount,
            showPrototeamOptions: state.step === Step.PrototeamOptions,
            showSelectedOptionSummary: state.step === Step.SelectedOptionSummary,
            showSuccess: state.step === Step.Success,
        };
    }
})