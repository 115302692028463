import styled from "styled-components"
import { Game, Team as TeamDto, Home, Visitor } from "@/state/data/types"
import homeJerseryPlaceholder from '@/assets/images/home_jersey.svg'
import visitorJerseryPlaceholder from '@/assets/images/visitor_jersey.svg'
import { useSeasonsData } from "@/state/data/useSeasonTeams"
import { useAppState } from "@/state/app/useAppState"
import { EventToFinalScore } from "@/libs/eventToWinLossScore"
import dayjs from "@/libs/dayjs"
import { MouseEvent } from "react"

const StyledFeatureGameCard = styled.div.attrs({
    className: "game-card"
})`
margin: 1px;
border-radius: inherit;
border: 1px solid transparent;

&:hover{
    cursor: pointer;
}
`

const Team = styled.div`
width: 100%;
display: flex;
color: #000;
background-color: #FFFFFF;
align-items: center;
`

const Logo = styled.img.attrs({
    className: "team-logo"
})`
grid-area: logo;
width: 68px;
height: 100%;
border-radius: 0 5px 5px 0;
object-fit: contain;
`

const Time = styled.div.attrs({
    className: "time"
})`
grid-area: time;
place-self: center flex-end;
flex: 100%;
padding: 8px;
font-size: 1.1rem;
font-weight: 500;
flex-basis: 100%;
text-transform: uppercase;
font-family: 'Rubik';
white-space: nowrap;
`

const Title = styled.div.attrs({
    className: "title"
})`
grid-area: title;
place-self: center flex-start;
flex: 100%;
display: flex;
flex-direction: column;
padding: 8px 8px 0 8px;
font-size: 0.9rem;
flex-basis: 100%;
text-transform: uppercase;
font-family: 'Rubik';
`

const Location = styled.div.attrs({
    className: "location"
})`
grid-area: location;
font-weight: 300;
font-size: 0.8rem;
padding: 0 8px;
`

const Score = styled.div`
padding: 4px;
text-align: left;
font-weight: 500;
font-size: 1.3rem;
font-family: 'Kapra Neue Pro';
place-self: center center;
grid-area: score;
`

const StyledDate = styled.div.attrs({
    className: "date"
})`
grid-area: date;
font-family: 'Rubik';
place-self: stretch stretch;
border-radius: 5px 0px 0px 5px;
text-transform: uppercase;
color: rgb(255, 255, 255);
font-weight: 400;
line-height: 1;
padding-top: 8px;
text-align: center;

display: flex;
flex-direction: column;
justify-content: center;
line-height: 1.4;

div {
    display: block;
    margin-top: -6px;
}

span {
    color: #CCC;
}
`

const Versus = styled.div.attrs({
    className: "versus"
})<{ $isHome?:boolean }>`
font-size: 2rem;
font-weight: 500;
font-family: 'Rubik';
text-align: center;

grid-area: vs;
`

const StyledListScheduledGameCard = styled.div.attrs({
    className: "game-card-list-scheduled"
})`
display: grid;
border-radius: 5px;
box-shadow: 0 3px 5px 0px rgb(0 0 0 / 10%);
background-color: #FFF;

grid-template-columns: 75px 2fr 20px 5fr;
grid-template-areas: 
    "date time vs title"
    "date location location location";

.date {
    background-color: #38393e;
}

.title {
    font-size: 18px;
    line-height: 21.33px;
}

.time {
    font-size: 24px;
    font-weight: 500;
    // font-family: 'Kapra Neue Pro';
    text-align: right;
    line-height: 28.44px;
}

.versus {
    font-size: 18px;
    font-weight: 300;
    align-self: center;
    font-style: none;
}

.location {
    font-size:12px;
}

`

const StyledListFinalGameCard = styled.div.attrs({
    className: "game-card-list-final"
})`
display: grid;
box-shadow: 0 3px 5px 0px rgb(0 0 0 / 10%);
grid-template-columns: 80px 35px auto 45px;
grid-template-areas: "score logo title date";
background-color: #FFF;
line-height: 1;

.team-logo {
    width: 35px;
}

.date {
    color: #38393e;
}
`

const FeaturedGame = styled.div`
display: grid;
background-color: #FFF;
border-radius: 0 0 5px 5px;
border: 2px solid ${p => p.theme.primaryColor};
margin-bottom: 10px;
padding-bottom: 8px;

grid-template-columns: 1fr 1fr 1fr;
grid-template-rows: auto auto auto auto;
grid-template-areas:
    "location location location"
    "visitor . home"
    "visitor time home"
    "visitor date home"
    "visitor . home"
    "visitor . home";

.title {
    line-height: 0.9;
    font-weight: 500;
    font=family: 'Rubik';
}

.location {
    text-align: center;
}

.date {
    color: #38393e;
    place-self: start center;
}

.time {
    place-self: end center;
    font-family: 'Kapra Neue Pro';
    font-size: 1.8rem;
    font-weight: 500;
    padding-bottom: 0;
}

> .featured-team {
    place-self: center center;
    text-align: right;

    .team-logo {
        place-self: end;
    }
}

> .featured-team ~ .featured-team {
    align-self: start;
    text-align: left;

    .team-logo {
        place-self: start;
    }
}
`

const GameCardHeader = styled.div`
background-color: ${p => p.theme.primaryColor};
border-radius: 5px 5px 0 0;
font-weight: 500;
font-family: 'Rubik';
font-size: 1.2rem;
text-transform: uppercase;
line-height: 1;
padding: 8px;
`

const FeaturedTeam = styled.div.attrs({
    className: "featured-team"
})`
display: grid;
grid-template-columns: 1fr;
grid-template-rows: 75px 1fr;
grid-template-areas:
    "logo"
    "title";

&::after {
    display: none;
}
`

const FeatureHomeTeam = styled(FeaturedTeam)`
grid-area: home;
`
const FeatureVisitorTeam = styled(FeaturedTeam)`
grid-area: visitor;
`


type GameCardProps = {
    game: Game,
    teamId: string
    hideText?: boolean,
    template?: 'featured' | 'live' | 'list-schedule' | 'list-final' 
}

export function GameCard({ game, template }: GameCardProps): JSX.Element {

    const app = useAppState()
    const { teams } = useSeasonsData();
    const toBeDetermined = { id: 0, title: "TBD", logo: "" };
    const navToBoxScore = app.navigateOnClick(`/games/${game.season.id}/${game.id}`)
    const team = (teams.find((v) => Number(v.id) == game.home?.id) ? game.visitor : game.home) || toBeDetermined;    

    const gameProps = {
        date: dayjs.utc(game.scheduledStartTime),
        onClick: navToBoxScore,
        team,
        isHome: team.id == game.home?.id,
        location: game.location
    }

    switch(template){
        case 'list-schedule': {
            return <ListScheduleGameCard {...gameProps } />
        }
            
        case 'list-final': {
            const { result, homeTeamScore, visitorTeamScore } = EventToFinalScore(game, app.selectedTeam)
            const props = { ...gameProps, result, homeScore: homeTeamScore, visitorScore: visitorTeamScore }
            return <ListFinalGameCard {...props} />
        }

        case 'featured': {
            const props = { ...gameProps, home: game.home, visitor: game.visitor }
            return <FeatureGameCard {...props} />
        }
    }

    return <></>

}

type GameCardTemplateProps = {
    date: dayjs.Dayjs
    onClick?: (event: MouseEvent<HTMLElement>) => void
}

type TeamScopedTemplateProps = GameCardTemplateProps & {
    team: TeamDto
    isHome: boolean
}

type ScheduleGameTemplateProps = TeamScopedTemplateProps & {
    location: string
}

type FinalGameTemplateProps = TeamScopedTemplateProps & {
    result: string
    visitorScore: number
    homeScore: number
}

type FeatureGameTemplateProps = ScheduleGameTemplateProps & {
    home: Home
    visitor: Visitor
}

export function ListScheduleGameCard({ team, isHome, date, location, onClick }: ScheduleGameTemplateProps){

    const time = date.format('h:mm A');
    const month = date.format('MMM').toUpperCase();
    const day = date.format('D');
    const dow = date.format('ddd').toUpperCase();

    return <StyledListScheduledGameCard onClick={onClick}>
        <StyledDate><div>{dow}<br/><span>{month} {day}</span></div></StyledDate>
        <Time>{time}</Time>
        <Versus $isHome={isHome}>{ isHome ? "vs" : "@" }</Versus>
        <Title>{team.title}</Title>
        <Location>{location}</Location>
    </StyledListScheduledGameCard>

}

export function ListFinalGameCard({ team, result, visitorScore, homeScore, date, onClick }: FinalGameTemplateProps){

    const month = date.format('MMM').toUpperCase();
    const day = date.format('D');

    return <StyledListFinalGameCard onClick={onClick}>
        <Score>{result} {visitorScore} - {homeScore}</Score>
        <Logo src={team.logo ? `${team.logo}/256` : homeJerseryPlaceholder} />
        <Title>{team.title}</Title>
        <StyledDate><div>{month}<br/>{day}</div></StyledDate>
    </StyledListFinalGameCard>

}

export function FeatureGameCard({ home, visitor, date, location, onClick }: FeatureGameTemplateProps){
    
    const time = date.format('h:mm A');
    const month = date.format('MMM').toUpperCase();
    const day = date.format('D');
    const dow = date.format('dddd').toUpperCase();

    return (<StyledFeatureGameCard onClick={onClick}>
        <GameCardHeader>
            Up Next
        </GameCardHeader>
        
        <FeaturedGame>
            <Location>{location}</Location>
            <Time>{time}</Time>
            <StyledDate><div>{dow}<br/><span>{month} {day}</span></div></StyledDate>
            <FeatureVisitorTeam>
                <Logo src={visitor.logo ? `${visitor.logo}/256` : visitorJerseryPlaceholder} />
                <Title>{visitor.title}</Title>
            </FeatureVisitorTeam>
            <FeatureHomeTeam>
                <Logo src={home.logo ? `${home.logo}/256` : homeJerseryPlaceholder} />
                <Title>{home.title}</Title>
            </FeatureHomeTeam>
            
        </FeaturedGame>

    </StyledFeatureGameCard>)
}

type GameCardTeamProps = {
    logo: string;
    title: string;
    subtitle: string;
    score: number|string;
    showScore?: boolean;
    hideText?: boolean;
}

export function GameCardTeam(props: GameCardTeamProps){
    return (<Team>
        <Logo src={props.logo}/>
        <Title>
            { props.hideText || <><div>{props.title}</div>
            <div className="subtitle">{props.subtitle}</div></> }
        </Title>
        { props.showScore && <Score>{props.score}</Score> }
    </Team>)
}