import { Button, FormWrapper } from "@gamesheet/ui";
import { TextInput } from "./components/TextInput";
import { FormEvent, FormEventHandler, forwardRef, useCallback, useImperativeHandle, useState } from "react";
import { FormFieldType, NewFormField, useCreateForm } from "@/forms/hooks/useCreateForm";
import { PasswordInput } from "./components/PasswordInput";
import { styled } from "styled-components";
import { PageLoading } from "@/components/pure/PageLoading";

const StyledSignupForm = styled.form`
    display: flex;
    flex-direction: column;
    gap: 16px;
`

type SignupFormProps = {
    onSubmit: (data:SignupFormState) => void;
}

export type SignupFormState = {
    firstName: FormFieldType<string>;
    lastName: FormFieldType<string>;
    email: FormFieldType<string>;
    password: FormFieldType<string>;
}

export const SignupForm = forwardRef(({ onSubmit }:SignupFormProps, ref) => {
    
    const { state: formState, onChange } = useCreateForm('signup', {
        firstName: NewFormField('', /.{2,}/, 'First Name must be at least 2 characters'),
        lastName: NewFormField('', /.{2,}/, 'Last Name must be at least 2 characters'),
        email: NewFormField('', /^[\w-\+\.]+@([\w-]+\.)+[\w-]{2,4}$/, 'Email must be valid'),
        password: NewFormField('', /.{8,}/, 'Password must be at least 8 characters'),
    })

    const handleSubmit = useCallback((e:FormEvent<HTMLFormElement>) => {
        e && e.preventDefault();
        
        for(const field of Object.values(formState)){
            if(!field.value.match(field.test)){
                console.log("Form Validation Failed")
                return;
            }
        }
        
        onSubmit(formState);
    }, [ onSubmit, formState ])

    useImperativeHandle(ref, () => {
        return { 
            submitForm: handleSubmit,
            loading: !formState,
        }
    }, [ handleSubmit, formState ])

    return <FormWrapper>
        <StyledSignupForm onSubmit={handleSubmit}>
            <TextInput state={formState.firstName} name="firstName" label="First Name" onChange={onChange} />
            <TextInput state={formState.lastName} name="lastName" label="Last Name" onChange={onChange} />
            <TextInput state={formState.email} name="signup-email" label="Email" onChange={onChange} autocomplete={false} />
            <PasswordInput state={formState.password} name="signup-password" label="Password" onChange={onChange} autocomplete={false} />
        </StyledSignupForm>
    </FormWrapper>

})