import styled from "styled-components"
import InfoIcon from '@mui/icons-material/Info';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import ErrorIcon from '@mui/icons-material/Error';
import WarningIcon from '@mui/icons-material/Warning';
import SuccessIcon from '@mui/icons-material/CheckCircle';
import React, { PropsWithChildren } from "react";

const StyledEntityCard = styled.div.attrs({
    className: "entityCard"
})`
display: flex;
flex-direction: row;
justify-content: space-between;
align-items: center;
background-color: #fff;
gap: 15px; 

img {
    height: 50px;
    width: 50px;
    object-fit: contain;
}
`

const Description = styled.div.attrs({
    className: "description"
})`
flex: 3;
padding-right: 5px;
`

const JerseyDescription = styled.div`
text-align: right;
`

const Title = styled.div`
font-size: 1.2rem;
`

const Subtitle = styled.div`
font-size: 0.8rem;
`

const Stat = styled.div`
flex: 1;

font-size: 1.5rem;
padding-right: 0.5rem;
`

const SubStat = styled.sup`
font-size: 0.8rem;`

type EntityCardProps = {
    title: string
    subtitle?: string | React.ReactNode
    stat?: string
    substat?: string
    image?: string
    Icon?: JSX.Element
}

export function EntityCard({ title, subtitle, stat, substat, image, Icon=<></> }: EntityCardProps){
    
    return (<>
        <StyledEntityCard>
            { image && <img src={image}/> }
            { Icon}
            <Description>
                <Title style={{textTransform: 'uppercase'}}>{title}</Title>
                <Subtitle>{subtitle}</Subtitle>
            </Description>
            <JerseyDescription>
                <Stat>{stat}</Stat>
                <SubStat>{substat}</SubStat>
            </JerseyDescription>

            {/* { stat && <Stat>{stat}</Stat> substat&&<SubStat>{substat}</SubStat>} */}
        </StyledEntityCard>
    </>)

}

const StyledMessageCard = styled(StyledEntityCard)<{ color:any }>`
display: block;
border-left: 8px solid ${(props) => props.theme[props.color]};
padding-left: 5px;

svg {
    color: ${(props) => props.theme[props.color]};
    font-size: 2.5rem;
    margin-left: 5px;
}

.entityCard {
    background-color: ${(props) => props.theme[props.color]}10; 
    .description {
        padding: 8px 0;
    }
}
`

const StyledInfoCard = styled(StyledMessageCard).attrs({ className: "infoCard" })``
const StyledAnnouncementCard = styled(StyledMessageCard).attrs({ className: "announcementCard" })``
const StyledErrorCard = styled(StyledMessageCard).attrs({ className: "errorCard" })``
const StyledWarningCard = styled(StyledMessageCard).attrs({ className: "warningCard" })``

type MessageCardProps = PropsWithChildren<{
    title: string
    message?: string
}>
export function InfoCard({ title, message, children }: MessageCardProps){
    return (<StyledInfoCard color="info">
        <EntityCard title={title} subtitle={message || children} Icon={<InfoIcon />} />
    </StyledInfoCard>)
}

export function AnnouncementCard({ title, message, children }: MessageCardProps){
    return (<StyledAnnouncementCard color="info">
        <EntityCard title={title} subtitle={message || children} Icon={<AnnouncementIcon />} />
    </StyledAnnouncementCard>)
}

export function ErrorCard({ title, message, children }: MessageCardProps){
    return (<StyledErrorCard color="danger">
        <EntityCard title={title} subtitle={message || children} Icon={<ErrorIcon />} />
    </StyledErrorCard>)
}

export function WarningCard({ title, message, children }: MessageCardProps){
    return (<StyledWarningCard color="primaryColor">
        <EntityCard title={title} subtitle={message || children} Icon={<WarningIcon />} />
    </StyledWarningCard>)
}

export function SuccessCard({ title, message, children }: MessageCardProps){
    return (<StyledMessageCard color="success">
        <EntityCard title={title} subtitle={message || children} Icon={<SuccessIcon />} />
    </StyledMessageCard>)
}