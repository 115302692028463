import { useAppState } from "@/state/app/useAppState";
import { useTeamsData } from "@/state/data/useTeams";

export function useSelectedTeamState(){

    const teams = useTeamsData();
    const app = useAppState();

    return teams.all().find(team => team.id === app.selectedTeam);



}