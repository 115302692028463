import { Button, Alert, FormWrapper } from "@gamesheet/ui";
import { ReactElement, useEffect, useMemo, useReducer, useState } from "react";
import { Roster } from "@/components/views/Roster";
import { useAppState } from "@/state/app/useAppState";
import { useSeasonsData } from "@/state/data/useSeasonTeams";
import { Navigate } from "react-router-dom";
import { useAppNavigation } from "@/hooks/useAppNavigation";
import IframeWithOtp from "@/components/utils/IframeWithOtp";
import { ActionType, RosterOption } from "./types";
import { Overlay, StyledBootstrapSelect, StyledFloatingMenu, RosterButton, TeamDisplay } from "./styles";
import { TeamRosterReducer } from "./reducer";
import { RosterCoach, RosterPlayer } from "@/state/data/useTeamRoster/types";
import { useRosterSelect } from "./useRosterSelect";
import { TeamSelector } from "./TeamSelector";
import { useReadLockedRosterData } from "@/state/data/useTeamRoster/useReadLockedRosters";
import LockImg from "@/assets/images/padlock.png";

export function TeamRosterPage() {

    useReadLockedRosterData()
    
    const app = useAppState()
    const seasonTeams = useSeasonsData()
    const { destination, handleNavigation, hasNavigation } = useAppNavigation(); // import navigation items. Use navigate('path') to navigate to a specific path.
    const navTeam = seasonTeams.firstUnlocked || seasonTeams.teams[0]
    const showActions = seasonTeams.hasUnlockedRoster

    const [state, dispatch] = useReducer(TeamRosterReducer, {
        activeMenu: null,
        menuOpen: false,
        rosterSelectOpen: false
    });

    const { 
        selected: roster, 
        options: rosterOptions, 
        isLocked: viewingLockedRoster,
        lockedRosters: lockedRosters,
        setSelectedRoster 
    } = useRosterSelect()

    console.log("DEBUG", lockedRosters, seasonTeams.hasLockedRoster)

    const activeMenu = state.activeMenu
    const menuOpen = state.menuOpen
    const rosterSelectOpen = state.rosterSelectOpen

    const setMenuState = (open:boolean) => dispatch({ type: ActionType.TOGGLE_MENU_STATE, state:{ menuOpen: open } })
    const setActiveMenu = (menu:ReactElement) => dispatch({ type: ActionType.SET_ACTIVE_MENU, state:{ activeMenu: menu } })
    const toggleRosterSelectMenu = () => dispatch({ type: ActionType.TOGGLE_ROSTER_SELECT, state:{ rosterSelectOpen } })
    const onClickCoach = (coach: RosterCoach) => !viewingLockedRoster && !coach.loading && app.navigate(`/roster/coach/${coach.id}`)
    const onClickPlayer = (player: RosterPlayer) => !viewingLockedRoster && !player.loading && app.navigate(`/roster/player/${player.id}`)

    const close = () => {

        app.menu.close()
        setMenuState(false)

        // set menu
        showActions && setActiveMenu(<StyledFloatingMenu>
            <Button onClick={open}>Actions</Button>
        </StyledFloatingMenu>)
    }

    const open = () => {

        app.menu.open()
        setMenuState(true)

        // set menu
        showActions && setActiveMenu(<StyledFloatingMenu>
            <Button onClick={handleNavigation('/roster/import/registry/usah')} variant="inverted">Import from USA Hockey</Button>
            <Button onClick={handleNavigation('/roster/import/registry/hcr')} variant="inverted">Import from Hockey Canada</Button>
            <Button onClick={handleNavigation('/roster/import/csv')} variant="inverted">Import from CSV</Button>
            <Button onClick={handleNavigation('/roster/player/new')} variant="inverted">Add New Player</Button>
            <Button onClick={handleNavigation('/roster/coach/new')} variant="inverted">Add New Coach</Button>
            <Button onClick={close}>Close</Button>
        </StyledFloatingMenu>)
    }

    // start menu closed
    useEffect(() => { app.layout.showMobile && close(); }, [])
    useEffect(() => !viewingLockedRoster && activeMenu ? app.ui('rightTray').set(activeMenu) : ()=>{}, [activeMenu, viewingLockedRoster])

    // we need this at the end of a component to return a redirect whenn required
    if (hasNavigation) {
        return <Navigate to={destination} />
    }

    console.log("Maliable Rosters", seasonTeams.withMaliableRosters)

    return (
        <>
            { state.rosterSelectOpen && <TeamSelector activeSeasons={seasonTeams} lockedRosters={lockedRosters} toggleRosterSelectMenu={toggleRosterSelectMenu} selectedRoster={roster} setSelectedRoster={setSelectedRoster}/> }
            <>
                { seasonTeams.hasLockedRoster  && <FormWrapper>
                    { roster.isLocked && <TeamDisplay>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', fontWeight: 'bold', fontSize: '18px', textAlign: 'left', backgroundColor: '#2e2e2e', color: '#FFF', borderRadius: '6px', height: '30px', paddingLeft: '20px', paddingRight: '10px' }}>
                            TEAM ROSTER
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingRight: '10px' }}>
                            <span style={{ width: '80%', fontStyle: 'italic', paddingLeft: '8px', paddingTop: '6px', fontSize: '14px' }}>
                                <div style={{ fontWeight: 'bold', fontStyle: 'normal', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                    <span>Team Roster Plays in:</span>
                                </div>
                                <span style={{ overflow: 'hidden', textOverflow: 'ellipsis', display: '-webkit-box', WebkitLineClamp: 1, WebkitBoxOrient: 'vertical' }}>{roster.seasonTitle}</span>
                            </span>
                            <div style={{ textAlign: 'left' }}>
                                <img src={LockImg} alt="Padlock" style={{ width: '25px', height: '25px' }} />
                            </div>
                        </div>
                    </TeamDisplay>}
                    <RosterButton onClick={ toggleRosterSelectMenu }>VIEW ALL ROSTERS</RosterButton>
                </FormWrapper> }

                {(!roster.players[0] && !roster.coaches[0]) ? (
                    <>
                        <Alert message={!seasonTeams.hasUnlockedRoster ? `Your active roster is locked` : `Roster is empty`} style="warning" />
                    </>
                ) : (
                    <>
                        <Overlay open={menuOpen} onClick={close} />
                        {app.layout.showMobile && (<Roster roster={roster} onClickCoach={onClickCoach} onClickPlayer={onClickPlayer} layout="vertical" />)}
                        {app.layout.showDesktop && (<IframeWithOtp id="team-roster" src={`${app.config.apps.leagueDashboard}/seasons/${roster.seasonId}/teams/${roster.displayTeamId}/roster/players`} />)}
                    </>
                )}
            </>
        </>
    );

}