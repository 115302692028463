import styled from "styled-components"

import SelectionArrowIcon from '@mui/icons-material/KeyboardArrowDown';
import AddNewTeam from '@mui/icons-material/Add'
import { useSelectedTeamState } from "@/state/team/useSelectedTeamState";
import { useAppState } from "@/state/app/useAppState";
import { useDeviceState } from "@/state/device/useDeviceState";
import { useParams } from "react-router-dom";

import logoPlaceholder from "@/assets/images/blank_jersey.svg"
import { NavButton } from "@/components/pure/NavButton";
import { useRef, useState } from "react";
import { useTeamsData } from "@/state/data/useTeams";
import { Button, Logo } from "@gamesheet/ui";
import { useSeasonsData } from "@/state/data/useSeasonTeams";
import { TeamPreview } from "@/components/pure/TeamPreview";

const StyledMobileUserBar = styled.div.attrs({
    className: "user-bar"
})`
display: flex;
align-items: center;
width: 100vw;
height: 60px;
background-color: ${props => props.theme.secondaryColor};
color: ${props => props.theme.textOnSecondaryColor};
position: fixed;
top: 0;
left: 0;
box-shadow: 0 0 10px 0 rgba(0,0,0,0.5);
z-index: 3301;

.logo-tabs {
    display: none;
}
`

const StyledDesktopUserBar = styled.div.attrs({
    className: "user-bar"
})`
width: 100vw;
margin-top: 32px;
height: 78px;
background-color: ${props => props.theme.secondaryColor};
color: ${props => props.theme.textOnSecondaryColor};
position: fixed;
top: 0;
left: 0;
box-shadow: 0 0 10px 0 rgba(0,0,0,0.5);
z-index: 100;

.logo-tabs {
    display: flex;
}

.selector {
    padding-left: 20px;
}

.inner-user-bar {
    max-width: 1280px;
    margin: 0 auto;
}
`

const InnerUserBar = styled.div.attrs({
    className: "inner-user-bar"
})`
width: 100vw;
display: flex;
justify-content: flex-start;
height: 78px;
`

const Tab = styled.button.attrs({
    className: "tab"
})`
min-width: 60px;
width: 80px;
text-align: center;
font-size: 0.8rem;
z-index: 3301;
background-color: transparent;
border: none;

a { 
    color: ${props => props.theme.textOnSecondaryColor};
    text-decoration: none;
}

a.active, a:hover { { 
    color: ${props => props.theme.primaryColor};
    text-decoration: none;
}
`

const Tabs = styled.div.attrs({
    className: "tabs"
})`
display: flex;
align-items: center;
z-index: 3301;
`

const StyledPrototeamSelection = styled.div.attrs({
    className: "prototeam-selection"
}) <{ state: string, $desktopWidth?: number }>`
display: flex;
flex-direction: column;
align-items: stretch;
justify-content: flex-start;
gap: 15px;

background-color: ${props => props.theme.secondaryColor};
bottom: ${props => props.state === "open" ? "0" : "100vh"};
position: fixed;
transition: bottom 0.2s ease-in-out;
z-index: 3301;

padding: 15px;

.team-selector {
    cursor: pointer;
    background-color: #FFF;
    border-radius: 4px;
    padding: 15px;
    display: grid;
    grid-template-columns: 60px 1fr;
    column-gap: 15px;

    .team-logo {
        padding: 0;
        place-self: center center;
    }
}
`

const DesktopPrototeamSelection = styled(StyledPrototeamSelection)`
left: calc((100vw - min(1280px, 100vw)) / 2);
height: calc(100vh - 110px);
width: ${props => props.$desktopWidth ? props.$desktopWidth : "425px"};
max-width: 425px;
z-index: 3301;
`

const MobilePrototeamSelection = styled(StyledPrototeamSelection)`
height: calc(100vh - 60px);
width: 100vw;
z-index: 3301;
`

type MenuContainerProps = { 
    open?: boolean; 
}

const Overlay = styled.div<MenuContainerProps>`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0);
    z-index: 3150;
    display: ${p => (p.open ? "block" : "none")};
    pointer-events: ${p => (p.open ? "auto" : "none")};
`;


export function UserBar() {

    const app = useAppState()
    const selectedTeam = useSelectedTeamState()
    const StyledUserBar = app.device.isMobile ? StyledMobileUserBar : StyledDesktopUserBar;
    const [selectorState, setSelectorState] = useState<string>("closed");
    const [ menuOpen, setMenuState ] = useState(false)
    const { teams, getPlaysIn } = useTeamsData()
    const data = useSeasonsData()
    const teamSelectorRef = useRef<HTMLDivElement>(null)
    const PrototeamSelection = app.layout.showMobile ? MobilePrototeamSelection : DesktopPrototeamSelection;

    const buttons = app.navigation.desktopItems();

    const toggleSelector = () => {
        selectorState == "closed" ? app.menu.open() : app.menu.close();
        let menuState = menuOpen === true ? false : true
        setMenuState(menuState)
        setSelectorState((current) => current === "closed" ? "open" : "closed")
    }

    const selectTeam = (id: string) => {
        app.menu.close();
        app.setSelectedTeam(id);
        setSelectorState("closed")
    }

    return <>
        <StyledUserBar>
            
            <InnerUserBar>

                <TeamPreview
                    ref={teamSelectorRef}
                    onClick={toggleSelector}
                    logo={selectedTeam?.logo || ""}
                    title={selectedTeam?.title || "No Active Team"}
                    subtitle={selectedTeam?.club || ""}
                    showSelector
                    selectorState={selectorState}
                    $stretch={app.device.isMobile}
                />

                {app.layout.showDesktop && <>
                    <div className="logo-tabs">
                        <Tabs>
                            {buttons.map((button, index) => (
                                <Tab key={index}>
                                    <NavButton button={button} />
                                </Tab>
                            ))}
                        </Tabs>
                    </div>
                </>}


            </InnerUserBar>

        </StyledUserBar>

        { app.layout.showDesktop && <Overlay open={menuOpen} onClick={toggleSelector} /> }
        <PrototeamSelection state={selectorState} $desktopWidth={teamSelectorRef.current?.clientWidth}>
            {teams.map((team, index) => (
                
                <TeamPreview
                    key={index}
                    onClick={() => selectTeam(team.id)}
                    logo={team.logo || ""}
                    title={team?.title || ""}
                    subtitle={`Plays In ${getPlaysIn(team.id)}`}
                    $stretch={app.device.isMobile}
                />
                
            ))}
            <Button style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} onClick={() => {
                app.navigate("invitation")
                setSelectorState("closed")
            }}>
                <AddNewTeam fontSize="small"/>
                <span style={{ fontSize:'16px' }}>Add New Team</span>
            </Button>
        </PrototeamSelection>

    </>
}