import { onAuthStateChanged, User as FirebaseUser } from "firebase/auth";
import { auth } from "@/libs/firebase";
import { useEffect } from "react";
import { atom, useSetRecoilState } from "recoil";
import { useAppState } from "@/state/app/useAppState";

export const firebaseUserState = atom<FirebaseUser | undefined>({
    key: "firebaseUserState",
    dangerouslyAllowMutability: true,
    default: undefined,
})

export const useSetFirebaseAuth = () => {
    
    const app = useAppState()
    const setFirebaseUser = useSetRecoilState(firebaseUserState)

    useEffect(() => {

        return onAuthStateChanged(auth, (user) => {
            console.log('onAuthStateChanged', user)
            setFirebaseUser(user || undefined)
            app.loading.complete('firebaseAuth')
        });

    }, [])

}