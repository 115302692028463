import { MouseEvent, useCallback, useState } from "react";
import { atom, useRecoilCallback, useRecoilState } from "recoil";

type EventCallback = (...args:any[]) => any;
const eventsMap = new Map<string, Set<EventCallback>>()

export function useEventsState(){

    const add = (key:string, callback:EventCallback) => {
        // console.log("events:add", key, callback)
        const set = eventsMap.get(key) || new Set()
        set.add(callback)
        eventsMap.set(key, set)
        return () => remove(key, callback)
    }

    const remove = (key:string, callback:EventCallback) => {
        // console.log("events:remove", key, callback)
        eventsMap.get(key)?.delete(callback)
    }

    const trigger = (key:string, ...args:any[]) => {
        // console.log("events:trigger", key)
        eventsMap.get(key)?.forEach(callback => {
            // console.log("events:exec", key, callback)
            callback(...args)
        })
    }

    const handler = useCallback((key:string) => {
        return (event:MouseEvent<HTMLElement>) => {
            // console.log("events:handler", key)
            event.preventDefault()
            trigger(key)
        }
    }, [])

    return {
        add,
        remove,
        trigger,
        handler,
    }

}