import { Button } from "@gamesheet/ui";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import IframeWithOtp from "@/components/utils/IframeWithOtp";
import { useAppState } from "@/state/app/useAppState";
import { Iframe, useIframeLoadingState } from "@/state/app/useIframeLoading";
import config from "@/config";
import { useUserState } from "@/state/user/useUserState";
import { TeamEvent, useTeamGame } from "@/state/data/useTeamEvents";

const StyledFloatingMenu = styled.div`
display:flex;
flex-direction:column;
row-gap: 5px;
align-items: flex-end;
z-index: 1000;

.button {
    display: ilnine-block;
}
`

export function SchedulePageBoxScore() {

  const app = useAppState();
  const user = useUserState();
  const { seasonId, gameId } = useParams();
  const game: TeamEvent = useTeamGame(gameId) as TeamEvent

  const [activeMenu, setActiveMenu] = useState(<></>)

  const close = () => {

    app.menu.close()

    // set menu
    setActiveMenu(<StyledFloatingMenu>
      <Button onClick={open}>Actions</Button>
    </StyledFloatingMenu>)
  }

  const fetchScoresheet = () => {
    const url = `${config.services.scoresheetExportApi}/service.scoresheets/v4/get-game/${gameId}`;

    return fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${user.tokens.access}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Failed to fetch scoresheet');
        }
        return response.blob();
      })
      .then((blob) => {
        // Create blob link to download
        const downloadLink = document.createElement('a');
        downloadLink.setAttribute('target', 'gamesheet-scoresheet');
        downloadLink.href = URL.createObjectURL(blob);
        downloadLink.download = `${gameId}.pdf`;
        downloadLink.click();

        // Cleanup
        URL.revokeObjectURL(downloadLink.href);
      })
      .catch((error) => {
        throw new Error(error.message);
      });
  };

  const open = () => {

    app.menu.open()

    // set menu
    setActiveMenu(<StyledFloatingMenu>
      <Button onClick={fetchScoresheet} variant="inverted">Download Scoresheet</Button>
      <Button onClick={close}>Close</Button>
    </StyledFloatingMenu>)
  }

  useEffect(() => {

    app.ui('leftTray').set(<Button onClick={() => window.history.back()} style={{margin:0}}>Back</Button>);
    ["completed", "final"].includes(game?.status) && app.ui('rightTray').set(activeMenu)

    return () => {
      app.ui('leftTray').clear()
      app.ui('rightTray').clear()
    }

  }, [activeMenu])

  useEffect(() => close(), [])

  return <>
    <IframeWithOtp id="boxscore" src={`${app.config.apps.statsWidget}/seasons/${seasonId}/games/${gameId}?configuration[logo]=false&configuration[navigation]=false&configuration[links]=false`} />
  </>
}