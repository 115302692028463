import { ErrorCard } from "@/components/pure/EntityCard";
import { useAppState } from "@/state/app/useAppState";
import { UserState } from "@/state/user/useUserState";
import { Button, FormWrapper, Logo } from "@gamesheet/ui";
import { useState, useMemo, useEffect } from "react";
import { styled } from "styled-components";

const LoginForm = styled.form`
display: flex;
gap: 0px;
width: 250px;
flex-direction: column;
align-items: stretch;

.errorCard {
    width: 250px;
    margin-bottom: 16px;
}
`

const LoginInput = styled.input`
font-size: 16px;
`

const LoginSection = styled.div`
display: block;
button {
    width: 100%;
    margin-bottom: 4px;
}`

export function LoginStep({ user, onSignup }:{ user:UserState, onSignup:() => void }) {

    const app = useAppState()

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [error, setError] = useState('')

    const login = async (e: React.FormEvent<HTMLFormElement>) => {
        console.log('login')
        e.preventDefault();
        user.SignIn(email, password)
            .then(() => setError(""))
            .catch((err: any) => setError(err))
    };

    return <>

        <div style={{
            textAlign: 'center',
            marginBottom: '16px',
        }}>
            <Logo logo='primary' color='white' contrast="darkbg" size='xxl'/>
        </div>

        <FormWrapper>
            <LoginForm onSubmit={login}>

                { error && <ErrorCard title="" message={error} /> }

                <div className="form-floating mb-3">

                    <LoginInput
                        required
                        type="text"
                        name="email"
                        id="email"
                        className="form-control"
                        placeholder="Email Address"
                        onChange={(e) => setEmail(e.target.value)} 
                        value={email}
                    />

                    <label htmlFor="invitationCode" className="floating">
                        Email Address
                    </label>

                </div>

                <div className="form-floating mb-3">

                    <LoginInput
                        required
                        type="password"
                        name="password"
                        id="password"
                        className="form-control"
                        placeholder="Password"
                        onChange={(e) => setPassword(e.target.value)} 
                        value={password}
                    />

                    <label htmlFor="invitationCode" className="floating">
                        Password
                    </label>

                </div>

                <LoginSection>
                    <Button type="submit" gs-style="solid" style={{ display: 'block' }}>Login</Button>
                    <Button type="button" gs-style="solid" variant="inverted" onClick={onSignup} label="Signup" />
                </LoginSection>
            </LoginForm>
        </FormWrapper>

    </>

}