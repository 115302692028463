import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil"
import { rawRosterState, rosterState } from "./atom.rosterstate"
import { ActiveRosterState } from "./selector.activeroster"
import { RosterPlayer, RosterCoach } from "./types"

export function useTeamRoster() {
    
    const setRoster = useSetRecoilState(rawRosterState)
    const roster = useRecoilValue(ActiveRosterState)
    
    return {
        ...roster,
        findPlayer(id: string){
            return [...roster.skaters, ...roster.goalies].find((player) => player.id === id)
        },
        findCoach(id: string){
            return roster.coaches.find((coach) => coach.id === id)
        },
        addCoach(coach: RosterCoach){
            setRoster(roster => ({
                ...roster,
                coaches: [{
                        ...coach,
                        loading: true,
                        season: {
                            id: 0,
                            title: '',
                            archived: false
                        }
                    },
                    ...roster.coaches
                ]
            }))
        },
        updateCoach(coach: RosterCoach){
            setRoster(roster => ({
                ...roster,
                coaches: roster.coaches.map((c) => c.id == coach.id ? {
                    ...coach,
                    loading: true,
                    season: {
                        id: 0,
                        title: '',
                        archived: false
                    }
                }: c)
            }))
        },
        removeCoach(id: string){
            setRoster(roster => ({
                ...roster,
                coaches: roster.coaches.filter((c) => c.id !== id)
            }))
        },
        addPlayer(player: RosterPlayer){
            setRoster(roster => ({
                ...roster,
                players: [{ ...player, loading:true,
                    season: {
                        id: 0,
                        title: '',
                        archived: false
                    } }, ...roster.players]
            }))
        },
        updatePlayer(player: RosterPlayer){
            setRoster(roster => ({
                ...roster,
                players: roster.players.map((p) => p.id == player.id ? { ...player, loading:true,
                    season: {
                        id: 0,
                        title: '',
                        archived: false
                    } } : p)
            }))
        },
        removePlayer(id: string){
            setRoster(roster => ({
                ...roster,
                players: roster.players.filter((p) => p.id !== id)
            }))
        },
    }
}
